import { useState } from "react";
import { PastNote } from "./PastNote";

const Style: { [key: string]: React.CSSProperties } = {
    container: {
        // width: "100%",
        // height: "30px",

        // display: "flex",
        // flexDirection: "row",
        // justifyContent: "space-between",

        // background: "#0B1437",
    },
};

type Props = {
    pastNotesData: {
        date: string,
        content: string
    }[],
}

export function PastNotes({
    pastNotesData,
}: Props) {

    let pastNotesItems = [];

    if (pastNotesData != null && pastNotesData != undefined && pastNotesData.length > 0) {
        // Make top-most Past Note
        let firstPastNoteData = pastNotesData.shift();
        if (firstPastNoteData != undefined) {
            pastNotesItems.push(
                <PastNote rounded={true}
                    date={`Note on ${firstPastNoteData.date}`}
                    content={ firstPastNoteData.content}>
                </PastNote>
            );
        }
        // Make Past Notes further down the list
        pastNotesData.map((note) =>
            pastNotesItems.push(
                <PastNote rounded={false}
                    date={`Note on ${note.date}`}
                    content={note.content}>
                </PastNote>
            )
        );
    }

    return (
        <div style={Style.container}>
            {pastNotesItems}
        </div>
    );
};











