import { useEffect, useRef, useState } from "react";
import { fetcher } from "../../../../../../utils/fetcher";
import { useAuthHeader } from "react-auth-kit";
import { TransactionModal } from "../../Transactions/TransactionModal";
import { moneyFormatter } from "../../../../../../utils/moneyFormatter";



const Style: { [key: string]: React.CSSProperties } = {
    container: {
        width: "500px",
        height: "420px",
        paddingBottom: "20px",
        display: "flex",
        flexDirection: "column",
        borderRadius: "15px",
        background: "rgba(17, 28, 68, 1)",
    },
    theadCell: {
        color: "rgba(163, 174, 208, 1)",
        textAlign: "left",
        fontSize: "14px",
        paddingLeft: "20px",
    },
    tbodyCell: {
        color: "white",
        textAlign: "left",
        fontSize: "14px",
        paddingTop: "10px",
        paddingLeft: "20px",
        paddingBottom: "10px",
    },
    modifyBtn: {
        background: "#2D65C1",
        border: "none",
        borderRadius: "20px",
        color: "white",

        cursor: "pointer",

        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        gap: "3px",

        width: "65px",
        height: "28px"
    },
};

type Props = {
    username: string,
    tableItems: Array<any>
}

export function RecentTransactionsCard({
    username,
    tableItems
}: Props) {
    
   const [showModal, setShowModal] = useState<Boolean>(false);
    // const [items, setItems] = useState<TableDataRow[]>(tableItems);

    // console.log("hahahahah items", items);

    // useEffect(() => {
    //     setItems(tableItems);
    // })



    

    const addUserModal = () => {
        setShowModal(true);
    }
    
    const downloadCSV = () => {
        const rows = [];
    
        function convertTableDataRowToArray(row: any) {
            return [row.id, row.action, row.amount, row.updatedAt];
        }
    
        for (let index = 0; index < tableItems.length; index++) {
            const eachTableDataRow = tableItems[index];
            rows.push(convertTableDataRowToArray(eachTableDataRow));
        }
    
        let csvContent = "data:text/csv;charset=utf-8,"
            + rows.map(e => e.join(",")).join("\n");
    
    
        var encodedUri = encodeURI(csvContent);
        window.open(encodedUri);
    }

    // useEffect(() => {
    //     const currentUrl = window.location.href;
    //     const urlSections = currentUrl.split("/");
    //     const userId = urlSections[urlSections.length - 1];

    //     (async () => {
    //         await fetcher(
    //             "GET", authHeader(),
    //             backend.current + `/transactions/user/${userId}`
    //         ).then(data => {
    //             console.log("data", data);
                
    //             for (let i = 0; i < data.length; i++) {
    //                 const eachTransaction = data[i];
    //                 tableDataTransactions.push({
    //                     id: eachTransaction.id,
    //                     type: eachTransaction.id,
    //                     amount: String(Number(eachTransaction.amount) / 100),
    //                     date: eachTransaction.updatedAt,
    //                 });
    //             }
    //             setTableItems(tableDataTransactions.map((each) =>
    //                 <tr key={"transactionId"+each.id}>
    //                     <td style={Style.tbodyCell}>{each.type}</td>
    //                     <td style={Style.tbodyCell}>{each.amount}</td>
    //                     <td style={Style.tbodyCell}>{each.date}</td>
    //                 </tr>
    //             ))
    //         }).catch(e => {
    //             console.error(e);
    //         });
    //     })();
    // })



    return (
        <div style={Style.container}>
            {/* Header row - Recent Transactions title, + Button, CSV Button */}
            <div style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                paddingTop: "20px",
                paddingLeft: "20px",
                paddingRight: "20px",
                paddingBottom: "20px",
                background: "rgba(27, 37, 75, 1)",
                borderTopLeftRadius: "20px",
                borderTopRightRadius: "20px"
            }}>
                <span style={{ color: "white", fontSize: "20px", fontWeight: "700" }}>Recent Transactions</span>
                <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                    <button type="button" style={Style.modifyBtn} onClick={addUserModal}>+</button>
                    {
                    showModal ?
                            <TransactionModal title="Create a Transaction"
                                btnRightLabel={"CREATE"}
                                btnRightOnClick={()=> alert("wip")}
                                username={username} amount={"0.00"}></TransactionModal>
                            :
                            <></>
                    }
                    <button type="button" style={Style.modifyBtn} onClick={downloadCSV}>
                        <img src={require("../../../assets/download.png")} alt="Download Icon" width={"15px"} height={"15px"} />
                        CSV
                    </button>
                </div>

            </div>
            {/* Table */}
            <div style={{
                overflowY: "scroll",
            }}>
                <table style={{
                    tableLayout: "fixed",
                    borderCollapse: "collapse",
                    width: "100%"
                }}>
                    <colgroup>
                        <col style={{ width: "25%" }}></col>
                        <col style={{ width: "25%" }}></col>
                        <col style={{ width: "50%" }}></col>
                    </colgroup>
                    <thead style={{background: "rgba(27, 37, 75, 1)",}}>
                        <tr>
                            <th style={Style.theadCell}>Type</th>
                            <th style={Style.theadCell}>Amount</th>
                            <th style={Style.theadCell}>Date</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            tableItems.map((each) =>
                                <tr key={"transactionId"+each.id}>
                                    <td style={Style.tbodyCell}>{each.action}</td>
                                    <td style={Style.tbodyCell}>{moneyFormatter(
                                        each.amount / 100,
                                        (each.gameName == "Jogo Do Dado" ? "BR$" : "$USD"),
                                        true)}</td>
                                    <td style={Style.tbodyCell}>{ (new Date(each.updatedAt)).toUTCString() }</td>
                                </tr>
                            )
                        }
                    </tbody>
                </table>
            </div>
        </div>
    );
};











