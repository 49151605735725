
type Props = {
    text: string
}

export function Breadcrumbs({
    text
}: Props) {

    return (
        <div style={{
            fontSize: "12px",
            color: "white"
          }}>
            {text}
          </div>
    );
};











