export function replacePlaceholders(input: string, replacements: string[]): string {
  // Use a regular expression to find all occurrences of {...} in the input string
  const regex = /{(\d+)}/g;
  let match;
  let result = input;
 
  // Iterate over all matches
  while ((match = regex.exec(input)) !== null) {
    // Extract the index from the match
    const index = parseInt(match[1], 10);
 
    // Check if the index is within the bounds of the replacements array
    if (index >= 0 && index < replacements.length) {
      // Replace the placeholder with the corresponding item from the replacements array
      result = result.replace(match[0], replacements[index]);
    }
  }
 
  return result;
 }
 