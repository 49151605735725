
const Style: { [key: string]: React.CSSProperties } = {
    container: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
    },
    searchbar: {
        display: "flex",
        alignItems: "center",
        gap: "10px",

        borderRadius: "50px",
        border: "solid 10px #111C44",
        
        paddingLeft: "15px",
        paddingRight: "15px",
        paddingTop: "5px",
        paddingBottom: "5px",
        
        height: "30px"
    },
    input: {
        background: "transparent",
        boxShadow: "none",
        border: "none",
        color: "white",
        fontSize: "14px",
        width: "220px",
        height: "30px"
    }
};

type Props = {
    title: string,
}

export function Header({
    title,
}: Props) {


    
    return (
        <div style={Style.container}>
            <h1>{title}</h1>
            <div style={Style.searchbar}>
                <img src={require("../../assets/header/search.png")} alt="Search Icon" width={"15px"} height={"15px"}/>
                <input type="text"
                    style={Style.input}
                    name="searchbar" id="searchbar"
                    placeholder="Search" autoComplete="off"
                    onKeyUp={(e: React.KeyboardEvent<HTMLInputElement>) => {
                        if (e.key === "Enter")
                            // Enter is pressed
                            alert((e.target as HTMLInputElement).value);
                            // TODO
                        }
                    }/>
            </div>
        </div>
    );
};











