export const i18nEn = {
  editProfile: "Edit Profile",
  support: "Support",
  signout: "Sign Out",

  welcome: "Welcome",
  updateYourInfo: "Update your info below",
  username: "Your username here...",
  firstName: "Your first name here...",
  lastName: "Your last name here...",
  save: "SAVE",
  cancel: "CANCEL",
  returnHome: "RETURN HOME",
  
  // Error messages
  usernameProfanityWarning: "Username can't contain profanity.",

  back: "Back",
  title: "Support",
  intro1: "We want your feedback!",
  intro2: "If you have any suggestions or see anything that doesn't look right please let us know!",
  required: "Required field",
  email: "Your email here...",
  message: "Your message here...",
  bottomBtn: "SUBMIT",

  // Toast
  saveSuccessful: "SAVE SUCCESSFUL",
};


export const i18nPt = {
  editProfile: "Editar Perfil",
  support: "Suporte",
  signout: "Sair",

  welcome: "Bem vindo",
  updateYourInfo: "Atualize suas informações abaixo",
  username: "Digite o nome de usuário...",
  firstName: "Digite o primeiro nome...",
  lastName: "Digite o sobrenome...",
  save: "SALVAR",
  cancel: "CANCELAR",
  returnHome: "VOLTAR PARA INÍCIO",

  // Error messages
  usernameProfanityWarning: "Nome de usuário não pode conter obscenidade.",

  back: "Voltar",
  title: "Suporte",
  intro1: "Se você tiver alguma sugestão ou viu algo que não esteja certo, entre em contato!",
  intro2: "",
  required: "Campo obrigatório",
  email: "Inserir e-mail...",
  message: "Sua mensagem aqui...",
  bottomBtn: "ENVIAR",

  // Toast
  saveSuccessful: "SALVO COM SUCESSO",
}