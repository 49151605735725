import { Route, Routes } from "react-router-dom"
import { Scan } from "./Scan"
import { Wifi } from "./Wifi"
import { Access } from "./Access"
import { Unknown } from "./Unknown"
import { Jackpot } from "../../games/BarDice100/assets/jackpot/Jackpot"
import { Ticket } from "../../games/BarDice100/assets/jackpot/Ticket"

export const Error = (props) => {

  return (
    <Routes>
      <Route path="/scan" element={
        <Scan/>
      }/>
      <Route path="/wifi" element={
        <Wifi 
          selectedLocation={props.selectedLocation} 
          ip={props.ip}
        />
      }/>
      <Route path="/permissions" element={
        <>
          <Jackpot shieldPattern={"spin"}/>
        </>
      }/>
      <Route path="/*" element={
        <Unknown/>
      }/>
    </Routes>
  )
}