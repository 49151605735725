import { GameCard } from "./GameCard"
import { Breadcrumbs } from "../shared/Breadcrumbs"
import { Header } from "../shared/Header"

const Style: { [key: string]: React.CSSProperties } = {
  gamesRow: {
    display: "flex",
    justifyContent: "space-around",
  },

};

export const Games = ({
  gameUrlRef
}: {
  gameUrlRef: React.MutableRefObject<string>,
}) => {


  return (
    <div style={{
      display: "flex",
      flexDirection: "column",
      gap: "20px",
    }}>
      {/* Breadcrumbs */}
      <Breadcrumbs text={"Pages / Game Selection"}></Breadcrumbs>
      {/* Top row */}
      <Header title="Select a game to continue"></Header>
      {/* Body */}
      {/* Row 1 */}
      <div style={Style.gamesRow}>
        <div onClick={() => gameUrlRef.current = "bardice100"}>
          <GameCard title={"Bar Dice 100"} gameImg="bardice100" url={"/super/bardice100"}></GameCard>
        </div>
        <div onClick={() => {
          gameUrlRef.current = "jogo"
        }}>
          <GameCard title={"Jogo Do Dado"} gameImg="jogo" url={"/super/jogo"}></GameCard>
        </div>
        <div onClick={() => {
          gameUrlRef.current = "mecca100";
        }}>
          <GameCard title={"Mecca 100"} gameImg="mecca100" url={"/super/mecca100"}></GameCard>
        </div>
        <div onClick={() => {
          gameUrlRef.current = "potodice100";
        }}>
          <GameCard title={"Potawatomi Dice 100"} gameImg="potodice100" url={"/super/potodice100"}></GameCard>
        </div>
      </div>
      {/* Row 2 */}
      <div style={Style.gamesRow}>
        <GameCard title={"Keno 414"} gameImg="keno414" url={"/super/keno414"}></GameCard>
        <GameCard title={"Java Jackpot"} gameImg="javaJackpot" url={"/super/javajackpot"}></GameCard>
        <GameCard title={"Q-Dice 100"} gameImg="qdice100" url={"/super/qdice100"}></GameCard>
        <GameCard title={"Mis Dados"} gameImg="misDados" url={"/super/misDados"}></GameCard>
      </div>
    </div>
  )
}
