import { useState } from "react";
import { TransactionModal } from "./TransactionModal";


const Style: { [key: string]: React.CSSProperties } = {
    container: {
        // width: "100%",
        maxHeight: "700px",
        padding: "10px",
        display: "flex",
        flexDirection: "column",

        borderRadius: "15px",

        background: "#111C44",
    },
    timeBtn: {
        background: "#1B254B",
        borderRadius: "5px",
        border: "none",
        color: "white",

        cursor: "pointer",

        display: "flex",
        // justifyContent: "space-between",
        alignItems: "center",
        gap: "5px",

        paddingLeft: "10px",

        width: "120px",
        height: "35px"
    },
    modifyBtn: {
        background: "#2D65C1",
        border: "none",
        borderRadius: "20px",
        color: "white",

        cursor: "pointer",

        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        gap: "3px",

        width: "75px",
        height: "30px"
    },
    theadCell: {
        borderBottom: "solid 1px rgb(50,50,60)",
        paddingBottom: "10px",
    },
    tbodyCell: {
        textAlign: "center",
        fontSize: "15px",
        paddingTop: "10px",
        paddingBottom: "10px",
    }
};

type Props = {

}

type TableDataRow = {
    username: string,
    amount: string,
    transaction: string
    date: string
    balance: string
};

            
        
        


export function TransactionsTable({

}: Props) {

    let tableDataTransactions: Array<TableDataRow> = [];

    const pickDateModal = () => {
        alert("WIP add pick-date modal");
    }
    
    let username = "user_123"
    let amount = "";
    const toggleModal = () => {
        setShowModal(!showModal);
    }
    const [showModal, setShowModal] = useState<Boolean>(false);
    const toggleTransactionModal = () => {
        setShowModal(true);
    }
    
    const downloadCSV = () => {
        const rows = [ ];
        
        function convertTableDataRowToArray(row:TableDataRow) {
            return [row.username, row.amount, row.transaction, row.date, row.balance];
        }
    
        for (let index = 0; index < tableDataTransactions.length; index++) {
            const eachTableDataRow = tableDataTransactions[index];
            rows.push(convertTableDataRowToArray(eachTableDataRow));
        }
    
        let csvContent = "data:text/csv;charset=utf-8," 
            + rows.map(e => e.join(",")).join("\n");
        
        
        var encodedUri = encodeURI(csvContent);
        window.open(encodedUri);
    }
    


    let singleTestData = {
        username: "user_123",
        amount: "-$50.75",
        transaction: "Bet",
        date: "	05/25/2022 03:01",
        balance: "$125.55",
    };

    

    for (let i = 0; i < 60; i++) {
        tableDataTransactions.push(singleTestData);
    }

    const tableItems = tableDataTransactions.map((each) =>
        <tr key={each.username}>
            <td style={Style.tbodyCell}>{each.username}</td>
            <td style={Style.tbodyCell}>{each.amount}</td>
            <td style={Style.tbodyCell}>{each.transaction}</td>
            <td style={Style.tbodyCell}>{each.date}</td>
            <td style={Style.tbodyCell}>{each.balance}</td>
        </tr>
    );


    return (
        <section>
            {/* Container */}
            <div style={Style.container}>
                {/* Top row */}
                <div style={{
                    display: "flex",
                    justifyContent: "space-between",
                    paddingLeft: "10px", paddingRight: "10px"
                }}>
                    <div style={{ display: "flex", alignItems: "center", gap: "30px" }}>
                        <h2>All Transactions</h2>
                        <button type="button" style={Style.timeBtn} onClick={pickDateModal}>
                            <img src={require("../../assets/calendar.png")} alt="Calendar Icon" width={"15px"} height={"15px"} />
                            Today
                        </button>
                    </div>
                    <div style={{ display: "flex", alignItems: "center", gap: "30px" }}>
                        <button type="button" style={Style.modifyBtn} onClick={toggleTransactionModal}>+</button>
                        {
                        showModal ?
                                <TransactionModal title="Contact Information" btnRightLabel={"CREATE"} btnRightOnClick={toggleModal}
                                    username={username} amount={amount}></TransactionModal>
                                :
                                <></>
                        }
                        <button type="button" style={Style.modifyBtn} onClick={downloadCSV}>
                            <img src={require("../../assets/download.png")} alt="Download Icon" width={"15px"} height={"15px"} />
                            CSV
                        </button>
                    </div>
                </div>
                {/* Table */}
                <div style={{
                    overflowY: "scroll",
                }}>
                    <table style={{
                        tableLayout: "fixed",
                        width: "100%"
                    }}>
                        <colgroup>
                            <col style={{ width:"20%"}}></col>
                            <col style={{ width:"20%"}}></col>
                            <col style={{ width:"20%"}}></col>
                            <col style={{ width:"20%"}}></col>
                            <col style={{ width:"20%"}}></col>
                        </colgroup>
                        <thead>
                            <tr>
                                <th style={Style.theadCell}>Username</th>
                                <th style={Style.theadCell}>Amount</th>
                                <th style={Style.theadCell}>Transaction</th>
                                <th style={Style.theadCell}>Date/Time</th>
                                <th style={Style.theadCell}>Account Balance</th>
                            </tr>
                        </thead>
                        <tbody >
                            {tableItems}
                        </tbody>
                    </table>
                </div>
                
            </div>
        </section>
    );
};











