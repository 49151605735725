export const i18nEn = {
  wifiHeader: "Connect to the {0} Wi-Fi network",
  wifiLine1: "To play {0}, simply join {1} in your device's settings!",
  wifiLine2: "Once connected, click below to navigate back start winning cash!",
  wifiButton: "Reconnect",
  scanHeader: "Scan to verify your location",
  scanLine1: "To play {0}, simply scan the QR code on the terminal!",
  scanLine2: "Afterwards you'll be automatically redirected to start playing!",
}

export const i18nPt = {}