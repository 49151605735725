import { useState } from "react";

const Style: { [key: string]: React.CSSProperties } = {
    pastNotesTab: {
        backgroundColor: "rgba(235, 238, 253, 1)",
        color: "rgba(52, 78, 209, 1)",
        borderBottom: "solid 1px rgb(40, 40, 40)",
        // borderTopLeftRadius: "5px",
        // borderTopRightRadius: "5px",
        paddingTop: "10px",
        paddingBottom: "10px",
        paddingLeft: "15px",
        paddingRight: "15px",
        display: "flex",
        justifyContent: "space-between",
    },
    pastNotesContent: {
        backgroundColor: "rgba(245, 246, 250, 1)",
        color: "rgba(138, 146, 166, 1)",
        paddingTop: "10px",
        paddingBottom: "10px",
        paddingLeft: "15px",
        paddingRight: "15px",
    },
    expandBtn: {
        width: "24px",
        height: "24px"
    }
};

type Props = {
    rounded: boolean,
    date: string,
    content: string
}

export function PastNote({
    rounded,
    date,
    content
}: Props) {


    // useState
    const [expand, setExpand] = useState<boolean>();

    return (
        <>
            <div style={{
                ...Style.pastNotesTab,
                borderTopLeftRadius: rounded ? "10px" : undefined,
                borderTopRightRadius: rounded ? "10px" : undefined
            }}>
                {`Note on ${date}`}
                <img src={require("../../../../assets/expand.png")} alt="Expand button"
                    style={{
                        ...Style.expandBtn,
                        transform: expand? "rotate(180deg)" : "", 
                    }}
                    onClick={() => setExpand(!expand)}>
                </img>
            </div>
            <div style={expand ? Style.pastNotesContent : { display: "none"}}>
                {content}
            </div>
        </>
    );
};











