import { useNavigate } from "react-router-dom"
import { useSignOut } from "react-auth-kit"
import { useRef } from "react";

const Style: { [key: string]: React.CSSProperties } = {
    container: {
        width: "270px",
        height: "100%",
        paddingLeft: "10px",
        paddingRight: "10px",
        display: "flex",
        flexDirection: "column",

        background: "#111C44",
    },
    menuItem: {
        display: "flex",
        justifyContent: "left",
        gap: "25px",

        marginTop: "20px",
        marginLeft: "70px",
        marginRight: "70px",

        cursor: "pointer",

        // background: "red"
    }
};

type Props = {
    gameUrlRef: React.MutableRefObject<string>,
}

export function AdminSideBar({
    gameUrlRef
}: Props) {


    // Use lib
    const navigate = useNavigate();
    const signOut = useSignOut();
    
    // PNG imports
    const gamesIcon = require("../../assets/adminSideBar/gameSelection.png");
    const homeIcon = require("../../assets/adminSideBar/home.png");
    const usersIcon = require("../../assets/adminSideBar/users.png");
    const transactionsIcon = require("../../assets/adminSideBar/transactions.png");
    const signoutIcon = require("../../assets/adminSideBar/signout.png");

    // Sorting out URLs
    const gamesUrls = ["bardice100", "jogo", "mecca100", "potodice100",
        "keno414", "javajackpot", "qdice100", "misDados"];
    const gameChildrenUrls = ["users", "transactions"];
    const url = window.location.href;
    const urlArr = url.split("/").slice(3); // eg. [baseurl]/super/bardice100 => ["super", "bardice100"]
    
    // Remember user clicking into a game card so that users cant bypass game card
    
    if (urlArr[0] == "super" && gamesUrls.includes(urlArr[1])) {
        gameUrlRef.current = urlArr[1];
    }

    const showGamesOnly = urlArr[0] == "super" && urlArr.length == 1;

    return (
        <section>
            <div style={Style.container}>
                {/* Company logo */}
                <div
                    onClick={() => {
                        navigate("/super", { replace: true, state: {} })
                    }}
                    style={{
                        display: "flex", justifyContent: "center", alignItems: "center",
                        // fontFamily: 'Days One',
                        // fontSize: "24px",
                        // color: "#e8c305",
                        height: "130px",
                        gap: "10px",
                        cursor: "pointer"
                    }}>
                    <img src={require("../../assets/adminSideBar/radsoftwareLogo.png")}
                        alt="Company Logo"
                        style={{
                            width: "225px",
                            height: "auto"
                        }} />
                </div>
                {/* Game selection sidebar menu */}
                {
                    showGamesOnly ?
                        <>{/* Games Selection Menu items */}
                            <div onClick={() => {
                                navigate("", { replace: true, state: {} });
                            }}
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    gap: "25px",
                                    marginTop: "20px",
                                    cursor: "pointer",
                                }}
                                id={"games"}>
                                <img src={gamesIcon} alt="" width={"20px"} height={"20px"} />
                                {"Game Selection"}
                            </div>
                            <div style={{
                                display: "flex",
                                justifyContent: "left",
                                gap: "25px",
                                marginTop: "20px",
                                marginLeft: "60px",
                                cursor: "pointer",
                            }} onClick={() => signOut()}>
                                <img src={signoutIcon} alt="" width={"20px"} height={"20px"} />
                                Sign Out
                            </div>
                        </>
                        :
                        <> {/* Game specific Menu items */}
                            <div onClick={() => {
                                navigate(`/super/${gameUrlRef.current}`, { replace: true, state: {} })
                            }}
                                style={Style.menuItem}
                                id={"home"}>
                                <img src={homeIcon} alt="" width={"20px"} height={"20px"} />
                                {"Dashboard"}
                            </div>
                            <div onClick={() => {
                                navigate(`/super/${gameUrlRef.current}/users`, { replace: true, state: {} })
                            }}
                                style={Style.menuItem}
                                id={"users"}>
                                <img src={usersIcon} alt="" width={"20px"} height={"20px"} />
                                {"Users"}
                            </div>
                            <div onClick={() => navigate(`/super/${gameUrlRef.current}/transactions`, { replace: true, state: {} })}
                                style={Style.menuItem}
                                id={"transactions"}>
                                <img src={transactionsIcon} alt="" width={"20px"} height={"20px"} />
                                {"Transactions"}
                            </div>
                            {/* Sign out */}
                            <div style={Style.menuItem} onClick={() => signOut()}>
                                <img src={signoutIcon} alt="" width={"20px"} height={"20px"} />
                                Sign Out
                            </div>
                        </>
                }
            </div>
        </section>
    );
};











