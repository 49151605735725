import { useEffect, useState } from "react"
import { Spin01 } from "./shield/spin/01"
import { Spin02 } from "./shield/spin/02"
import { Spin03 } from "./shield/spin/03"
import { Spin04 } from "./shield/spin/04"
import { Spin05 } from "./shield/spin/05"
import { Spin06 } from "./shield/spin/06"
import { Spin07 } from "./shield/spin/07"
import { Spin08 } from "./shield/spin/08"
import { Spin09 } from "./shield/spin/09"
import { Spin10 } from "./shield/spin/10"
import { Spin11 } from "./shield/spin/11"
import { Spin12 } from "./shield/spin/12"
import { Spin13 } from "./shield/spin/13"
import { Spin14 } from "./shield/spin/14"
import { Win001 } from "./shield/win/001"

export const Jackpot = (props) => {
  const shield = {
    spin: {
      len: 2,
      interval: Math.ceil(1000/14),
      animation: {
        "1": <Spin01 w={props?.w ?? 1000} h={props?.h ?? 325} v={props?.v ?? "0 0 1000 325"}/>,
        "2": <Spin02 w={props?.w ?? 1000} h={props?.h ?? 325} v={props?.v ?? "0 0 1000 325"}/>,
        "3": <Spin03 w={props?.w ?? 1000} h={props?.h ?? 325} v={props?.v ?? "0 0 1000 325"}/>,
        "4": <Spin04 w={props?.w ?? 1000} h={props?.h ?? 325} v={props?.v ?? "0 0 1000 325"}/>,
        "5": <Spin05 w={props?.w ?? 1000} h={props?.h ?? 325} v={props?.v ?? "0 0 1000 325"}/>,
        "6": <Spin06 w={props?.w ?? 1000} h={props?.h ?? 325} v={props?.v ?? "0 0 1000 325"}/>,
        "7": <Spin07 w={props?.w ?? 1000} h={props?.h ?? 325} v={props?.v ?? "0 0 1000 325"}/>,
        "8": <Spin08 w={props?.w ?? 1000} h={props?.h ?? 325} v={props?.v ?? "0 0 1000 325"}/>,
        "9": <Spin09 w={props?.w ?? 1000} h={props?.h ?? 325} v={props?.v ?? "0 0 1000 325"}/>,
        "10": <Spin10 w={props?.w ?? 1000} h={props?.h ?? 325} v={props?.v ?? "0 0 1000 325"}/>,
        "11": <Spin11 w={props?.w ?? 1000} h={props?.h ?? 325} v={props?.v ?? "0 0 1000 325"}/>,
        "12": <Spin12 w={props?.w ?? 1000} h={props?.h ?? 325} v={props?.v ?? "0 0 1000 325"}/>,
        "13": <Spin13 w={props?.w ?? 1000} h={props?.h ?? 325} v={props?.v ?? "0 0 1000 325"}/>,
        "14": <Spin14 w={props?.w ?? 1000} h={props?.h ?? 325} v={props?.v ?? "0 0 1000 325"}/>,
      }
    },
    win: {
      len: 3,
      interval: Math.ceil(1000/164),
      // animation: {
      //   "1": <Win001 w={props?.w ?? 1000} h={props?.h ?? 325} v={props?.v ?? "0 0 1000 325"}/>,
      // }
    }
  }
  const shieldPattern = props?.shieldPattern ?? "spin"
  const [shieldFrame, setShieldFrame] = useState(1)

  useEffect(()=>{
    const interval = setInterval(()=>{
      let calculatedFrame = Math.ceil(
        (Date.now()%1000) / shield[shieldPattern].interval
      );
      setShieldFrame(calculatedFrame||1)
    }, shield[shieldPattern].interval)

    return ()=>{
      clearInterval(interval)
    }
  }, [shieldPattern])

  // const stars = {}
  // const starPattern = props.starPattern
  // const starFrame = useRef("01")

  // useEffect(()=>{

  // }, [starPattern])

  return (
    <div className="jackpotShield" onClick={()=>{props?.setModalOpen(true)}}>
      <div className="jackpotAmount" style={{
        fontSize: props.fontSize,
        width: props.w,
        height: props.h,
      }}>
        {props.amount}
      </div>
        {shield[shieldPattern].animation[shieldFrame]}
      {/* <div className="jackpotStars">
        {stars[starPattern].animation[starFrame]}
      </div> */}
    </div>
  )
}