import { ToggleSwitch } from "../../shared/ToggleSwitch";


const Style: { [key: string]: React.CSSProperties } = {
    container: {
        width: "400px",
        // height: "675px",

        padding: "20px",

        display: "flex",
        flexDirection: "column",

        borderRadius: "15px",

        background: "#111C44",
    },
    contentsRow: {
        display: "flex",
        // border: "red solid 2px",
        justifyContent: "space-around",
        alignItems: "center"
    },
    rowItemGroup: {
        // padding: "0px"
    },
    roundBtn: {
        width: "100px",
        height: "100px",
        fontSize: "24px",
        fontWeight: "700",
        
        display: "flex",
        justifyContent: "center",
        alignItems: "center",

        color: "rgba(163, 174, 208, 1)",
        borderRadius: "50%",
        background: "rgba(27, 37, 75)",
        border: "none",
        cursor: "pointer"
    },
};

type Props = {

}


export function ActionsCard({

}: Props) {

    function blockWithdrawalsClick() {
        alert("WIP clicked Block Withdrawals");
    }
    function blockDepositsClick() {
        alert("WIP clicked Block Deposits");
    }
    function deleteAccountClick() {
        alert("WIP clicked Delete Account");
    }
    function contactUserClick() {
        alert("WIP clicked Contact User");
    }


    return (
        <div style={Style.container}>
            {/* Title */}
            <div style={{ marginBottom: "20px", fontSize: "24px", fontWeight: "700" }}>
                Actions
            </div>
            {/* Block Withdrawals/Deposits */}
            <div style={{
                marginTop: "20px", marginBottom: "100px",
                display: "flex", flexDirection: "column", gap: "20px",
            }}>
                <div style={{ display: "flex", gap: "20px", alignItems: "center"}} onClick={blockWithdrawalsClick}>
                    <ToggleSwitch label={"Block Withdrawals"} id={"blockWithdrawals"} >
                    </ToggleSwitch>
                    
                </div>
                <div style={{ display: "flex", gap: "20px", alignItems: "center" }} onClick={blockDepositsClick}>
                    <ToggleSwitch label={"Block Deposits"} id={"blockDeposits"} >
                    </ToggleSwitch>
                </div>
            </div>
            {/* Round buttons */}
            <div style={{
                display: "flex", justifyContent: "space-around"
            }}>
                <div>
                    <button style={Style.roundBtn} type="button" onClick={deleteAccountClick}>
                        <img src={require("../../../assets/deleteAccount.png")} alt="Delete icon" width={"40px"} height={"40px"} />
                    </button>
                    <p style={{ color: "rgba(163, 174, 208)", fontSize: "14px", textAlign: "center" }}>Delete Account</p>
                </div>
                <div>
                    <button style={Style.roundBtn} type="button" onClick={contactUserClick}>
                        <img src={require("../../../assets/contactUser.png")} alt="Contact icon" width={"40px"} height={"40px"} />
                    </button>
                    <p style={{ color: "rgba(163, 174, 208)", fontSize: "14px", textAlign: "center" }}>Contact User</p>
                </div>
            </div>
        </div>
    );
};











