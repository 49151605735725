
import { useEffect, useState } from "react"
import { UsersTable } from "../Users/UsersTable"
import { Breadcrumbs } from "../shared/Breadcrumbs"
import { Header } from "../shared/Header"

export const Dashboard = ({
  gameUrlRef
}: {
  gameUrlRef: React.MutableRefObject<string>,
}) => {
  
  const [title, setTitle] = useState<string>("");

  useEffect(() => {
    switch (gameUrlRef.current) {
      case "bardice100":
        setTitle("Bar Dice 100");
        break;
      case "jogo":
        setTitle("Jogo Do Dado");
        break;
      case "mecca100":
        setTitle("Mecca 100");
        break;
      case "potodice100":
        setTitle("Potawatomi Dice 100");
        break;
      case "keno414":
        setTitle("Keno 414");
        break;
      case "javajackpot":
        setTitle("Java Jackpot");
        break;
      case "qdice100":
        setTitle("Q-Dice 100");
        break;
      case "misDados":
        setTitle("Mis Dados");
        break;
      default:
        break;
    }
  }, []);

  return (
    <div>
      {/* Breadcrumbs */}
      <Breadcrumbs text={`Pages / ${title} / Dashboard`}></Breadcrumbs>
      {/* Top row */}
      <Header title={`${title} Main Dashboard`}></Header>
      {/* Body */}

    </div>
  )
}