import { useState } from "react";


const Style: { [key: string]: React.CSSProperties } = {
    container: {
        // width: "100%",
        maxHeight: "700px",
        padding: "10px",
        display: "flex",
        flexDirection: "column",

        borderRadius: "15px",

        background: "#111C44",
    },
    timeBtn: {
        background: "#1B254B",
        borderRadius: "5px",
        border: "none",
        color: "white",

        cursor: "pointer",

        display: "flex",
        // justifyContent: "space-between",
        alignItems: "center",
        gap: "5px",

        paddingLeft: "10px",

        width: "120px",
        height: "35px"
    },
    modifyBtn: {
        background: "#2D65C1",
        border: "none",
        borderRadius: "20px",
        color: "white",

        cursor: "pointer",

        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        gap: "3px",

        width: "75px",
        height: "30px"
    },
    theadCell: {
        borderBottom: "solid 1px rgb(50,50,60)",
        paddingBottom: "10px",
    },
    tbodyCell: {
        textAlign: "center",
        fontSize: "15px",
        paddingTop: "10px",
        paddingBottom: "10px",
    }
};

type Props = {
    data: Array<TableDataRow>
}


type TableDataRow = {
    userid: string,
    email: string,
    // username: string,
    balance: string,
    role: string,
    deposits: string
    withdrawals: string
    bets: string
    profits: string
    winrate: string
};


export function UsersTable({
    data
}: Props) {

    const pickDateModal = () => {
        alert("WIP add pick-date modal");
    }

    const addUserModal = () => {
        alert("WIP add add-user modal");
    }

    const downloadCSV = () => {
        const rows = [];
        function convertTableDataRowToArray(row: TableDataRow) {
            return [row.email, row.balance, row.deposits, row.withdrawals, row.bets, row.profits, row.winrate];
        }
        for (let index = 0; index < data.length; index++) {
            const eachTableDataRow = data[index];
            rows.push(convertTableDataRowToArray(eachTableDataRow));
        }
        let csvContent = "data:text/csv;charset=utf-8,"
            + rows.map(e => e.join(",")).join("\n");
        var encodedUri = encodeURI(csvContent);
        window.open(encodedUri);
    }



    // let temp = [];
    // for (let i = 0; i < data.length; i++) {
    //     const each = data[i];
    //     temp.push({
    //         username: each.username,
    //         balance: String(each.balance),
    //         deposits: "$50.75",
    //         withdrawals: "$50.75",
    //         bets: "$50.75",
    //         profits: "$50.75",
    //         winrate: "100%",
    //     });
    // }

    const tableItems = data.map((each) =>
        <tr key={each.email}>
            <td style={Style.tbodyCell}>
                <a href={"/super/users/" + each.userid}
                    style={{
                        color: "white",
                        textDecoration: "none"
                    }}>
                    {each.email}
                </a>
            </td>
            <td style={Style.tbodyCell}>{each.userid}</td>
            <td style={Style.tbodyCell}>{each.balance}</td>
            <td style={Style.tbodyCell}>{each.role}</td>
            <td style={Style.tbodyCell}>{each.deposits}</td>
            <td style={Style.tbodyCell}>{each.withdrawals}</td>
            <td style={Style.tbodyCell}>{each.bets}</td>
            <td style={Style.tbodyCell}>{each.profits}</td>
            <td style={Style.tbodyCell}>{each.winrate}</td>
        </tr>
    );


    return (
        <section>
            {/* Container */}
            <div style={Style.container}>
                {/* Top row */}
                <div style={{
                    display: "flex",
                    justifyContent: "space-between",
                    paddingLeft: "10px", paddingRight: "10px"
                }}>
                    <div style={{ display: "flex", alignItems: "center", gap: "30px" }}>
                        <h2>All Users</h2>
                        <button type="button" style={Style.timeBtn} onClick={pickDateModal}>
                            <img src={require("../../assets/calendar.png")} alt="Calendar Icon" width={"15px"} height={"15px"} />
                            Today
                        </button>
                    </div>
                    <div style={{ display: "flex", alignItems: "center", gap: "30px" }}>
                        <button type="button" style={Style.modifyBtn} onClick={addUserModal}>+</button>
                        <button type="button" style={Style.modifyBtn} onClick={downloadCSV}>
                            <img src={require("../../assets/download.png")} alt="Download Icon" width={"15px"} height={"15px"} />
                            CSV
                        </button>
                    </div>
                </div>
                {/* Table */}
                <div style={{
                    overflowY: "scroll",
                }}>
                    <table style={{
                        tableLayout: "fixed",
                        width: "100%"
                    }}>
                        <colgroup>
                            <col style={{ width: "20%" }}></col>
                            <col style={{ width: "10%" }}></col>
                            <col style={{ width: "10%" }}></col>
                            <col style={{ width: "10%" }}></col>
                            <col style={{ width: "10%" }}></col>
                            <col style={{ width: "10%" }}></col>
                            <col style={{ width: "10%" }}></col>
                            <col style={{ width: "10%" }}></col>
                            <col style={{ width: "10%" }}></col>
                        </colgroup>
                        <thead>
                            <tr>
                                <th style={Style.theadCell}>Email</th>
                                <th style={Style.theadCell}>User ID</th>
                                <th style={Style.theadCell}>Balance</th>
                                <th style={Style.theadCell}>Role</th>
                                <th style={Style.theadCell}>Total Deposits</th>
                                <th style={Style.theadCell}>Total Withdrawals</th>
                                <th style={Style.theadCell}>Total Bets</th>
                                <th style={Style.theadCell}>Total Profit</th>
                                <th style={Style.theadCell}>Win Rate</th>
                            </tr>
                        </thead>
                        <tbody >
                            {
                                tableItems.length == 0 ?
                                    <tr>
                                        <td style={Style.tbodyCell}>Loading...</td>    
                                        <td style={Style.tbodyCell}>Loading...</td>    
                                        <td style={Style.tbodyCell}>Loading...</td>    
                                        <td style={Style.tbodyCell}>Loading...</td>    
                                        <td style={Style.tbodyCell}>Loading...</td>    
                                        <td style={Style.tbodyCell}>Loading...</td>    
                                        <td style={Style.tbodyCell}>Loading...</td>    
                                        <td style={Style.tbodyCell}>Loading...</td>    
                                        <td style={Style.tbodyCell}>Loading...</td>    
                                    </tr>
                                    :
                                    tableItems
                            }
                        </tbody>
                    </table>
                </div>

            </div>
        </section>
    );
};











