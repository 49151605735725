import { Breadcrumbs } from "../../../shared/Breadcrumbs";
import { Header } from "../../../shared/Header";
import { ActionsCard } from "../ActionsCard";
import { ContactInfoCard } from "../ContactInfoCard";
import { NotesCard } from "../NotesCard";
import { RecentBetsCard } from "../RecentBetsCard";
import { RecentTransactionsCard } from "../RecentTransactionsCard";
import { UserSummaryCard } from "../UserSummaryCard";
import { PastNotes } from "./PastNotes";


const Style: { [key: string]: React.CSSProperties } = {
    container: {
        // width: "100%",
        maxHeight: "700px",
        padding: "10px",
        display: "flex",
        flexDirection: "column",

        borderRadius: "15px",

        background: "#111C44",
    },
    btn: {
        background: "#2D65C1",
        border: "none",
        borderRadius: "20px",
        color: "white",

        cursor: "pointer",

        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        gap: "3px",

        width: "100px",
        height: "25px"
    },
    
};

type Props = {

}


export function UserNotes({

}: Props) {
    let username = "User_123";

    let pastNotesData = [
        {
            date: "5/25/22",
            content: `When a note is selected it will expand to its entire length, pushing the notes below it down. \r\n* Lorem Ipsum \r\n* Lorem Ipsum`
        },
        {
            date: "4/17/22",
            content: "I reject your reality and substitute my own."
        },
        {
            date: "3/27/22",
            content: "Testing 123123."
        }
    ];

    function saveNote() {
        alert("WIP save note!")
    }

    function saveAndClose() {
        alert("WIP save and close!")
    }

    return (
        <div style={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            padding: "10px"
        }}>
            {/* Breadcrumbs */}
            <Breadcrumbs text={`Pages / Users / ${username} / Notes`}></Breadcrumbs>
            {/* Top row */}
            <Header title={`${username} Notes`}></Header>
            {/* Body */}
            <section style={{
                display: "flex",
                flexDirection: "column",
                gap: "20px",

                // width: "1590px",
                height: "829px",

                borderRadius: "20px",
                backgroundColor: "rgba(17, 28, 68, 1)"
            }}>
                {/* Row 1 */}
                <div style={{
                    display: "flex", flexDirection: "column", justifyContent: "left", gap: "20px", padding: "20px"
                    // , paddingRight: "20px"
                }}>
              
                        <h4 style={{ padding: "0px", margin: "0px" }}>New Note</h4>
                        <textarea name="" id="" style={{
                            // width: "1495px",
                            height: "275px"
                        }}
                            cols={30} rows={10} placeholder="Enter a new note about the user here"></textarea>
                        <div>
                            <button style={Style.btn} type="button" onClick={saveNote}>Save Note</button>
                        </div>
     
                </div>
                {/* Notes history */}
                <div style={{
                    display: "flex", flexDirection: "column", justifyContent: "left", gap: "20px", padding: "20px"
                }}>
                    <h4 style={{ padding: "0px", margin: "0px" }}>Past Notes</h4>
                    <div>
                        <PastNotes pastNotesData={pastNotesData}></PastNotes>
                    </div>
                    <div>
                        <button style={Style.btn} type="button" onClick={saveAndClose}>Save & Close</button>
                    </div>
                </div>

            </section>
        </div>
    );
};











