import RadSoftLogo from "../../_assets/RadSoftLogo.png"
import { useLocation, useNavigate } from "react-router-dom"
import { useEffect, useRef, useState } from "react"
import { moneyFormatter } from "../../../utils/moneyFormatter"
import { phoneFormatter } from "../../../utils/phoneFormatter"
import { CashierAmountIcon } from "../assets/CashierAmountIcon"
import { CashierBankIcon } from "../assets/CashierBankIcon"
import { CashierDepositIcon } from "../assets/CashierDepositIcon"
import { CashierWithdrawIcon } from "../assets/CashierWithdrawIcon"
import { CashierLocationIcon } from "../assets/CashierLocationIcon"
import { BarDiceNav } from "../../../games/BarDice100/controller/components/BarDiceNav"
import { BarDiceLogo } from "../../../games/BarDice100/assets/BarDiceLogo"
import { BackButton } from "../../../assets/icons/BackButton"
import { isValidEmail } from "../../../utils/isValidEmail"
import pixLogo from "../assets/pixLogo.png"

export const CashierRequest = (props) => {
  const navigate = useNavigate();
  const location = useLocation()
  const transactionDropdown = useRef(null)
  const locationDropdown = useRef(null)
  const pixKeyTypeDropdown = useRef(null)

  useEffect(()=>{
    if (props.locations.length && !props.locations.filter(l=>l.cashEnabled).length) {
      props.setTransactionType("ACH")
    }
  }, [props.locations])

  const handleInputChange = (e) => {
    const value = e.target.value;
    if (!isNaN(Number(value)) && value !== "") {
      props.setAmount(Number(value)); 
    } else {
      props.setAmount(0);
    }
  };

  const handlePix = (e, setFunction) => {
    let pix
    let type = props.pixKeyType
    if (type==="CPF") {pix = /\d{0,11}/.exec(e.target.value)?.[0]}
    else if (type==="PHONE") {pix = /[+]?\d+/.exec(e.target.value)?.[0]}
    else if (type==="EVP") {pix = /[a-z|0-9|\-]+/.exec(e.target.value)?.[0]}
    else {pix = isValidEmail(e.target.value) ? e.target.value : null}

    setFunction(pix ?? "")
  }

  const handleTransactionTypeSelect = (e) => {
    props.setTransactionType(e.target.value)
    transactionDropdown.current.blur();
  };

  const handleLocationSelect = (e) => {
    props.setSelectedLocation(JSON.parse(e.target.value));
    locationDropdown.current.blur();
  };

  // prevent ios from adding space under input
  const [iosHack, setIosHack] = useState("")
  useEffect(()=>{
    setIosHack(document?.getElementsByClassName('iosHack')[0]?.clientHeight)
  }, [props.requestType])

  return (
    <div id="cashier">
      <BarDiceNav
          balance={props.balance}
          user={props.user}
          selectedLocation={props.selectedLocation}
          bet={0}
          jogo={props.jogo}
          jackpot={props.jackpotValue}
          modalOpen={props.modalOpen}
          modalData={props.modalData}
          setModalOpen={props.setModalOpen}
          setModalData={props.setModalData}
        />
      <div className="cashierHome">
        <div>{!props.requestType ? (
          <div className="requestType">
            <div 
              className="fieldRow" 
              onClick={(e)=>{props.setRequestType("Deposit")}}
            >
              <CashierDepositIcon w={25} h={25} v={"8 5 24 24"} />
              <div className="text">{props.i18nMakeADeposit}</div>
            </div>
            <div 
              className="fieldRow" 
              onClick={(e)=>{props.setRequestType("Withdraw")}}
            >
              <CashierWithdrawIcon w={25} h={25} v={"8 5 24 24"} />
              <div className="text">{props.i18nMakeAWithdrawal}</div>
            </div>
          </div>
        ) : (
          <div className="requestDetails">
            <div className="back">
              <div><BackButton color={"#e8c305"}/></div>
              <div className="back-text" 
                onClick={(e)=>{props.setRequestType(undefined)}}
              >
                {props.i18nBack}
              </div>
            </div>
            <div className="fieldRow">
              {props.requestType==="Deposit" ? (
                <CashierDepositIcon w={25} h={25} v={"8 5 24 24"} />
              ) : (
                <CashierWithdrawIcon w={25} h={25} v={"8 5 24 24"} />
              )}
              <div className="text">
                {props.requestType==="Deposit" ? props.i18nDepositRequest : props.i18nWithdrawRequest}
              </div>
            </div>
            <div className="fieldRow">
              <CashierAmountIcon w={55} h={55} v={"8 5 55 55"} />
              <div className="text iosHack" style={{height: `${iosHack}px`, maxHeight: `${iosHack}px`}}>
                <div>{props.requestType==="Deposit" ? props.i18nAmountToDeposit : props.i18nAmountToWithdraw}</div>
                <input id="amount"
                  placeholder={`Min ${props.requestType} $5`}
                  value={props.amount === 0 ? "" : props.amount}
                  onChange={handleInputChange}
                  inputMode="numeric"
                />
              </div>
            </div>
            {props.transactionType!=="Pix" ? (<>
              <div className="fieldRow">
                <CashierBankIcon w={55} h={55} v={"8 5 55 55"} />
                <div className="column text">
                  <div>{props.requestType == "Deposit" ? props.i18nDepositType : props.i18nWithdrawalType}</div>
                  <select id="type"
                    name="transactionType"
                    value={props.transactionType}
                    onChange={handleTransactionTypeSelect}
                    ref={transactionDropdown}
                    required={true}
                    placeholder={props.i18nSelectAnOption}
                  >
                    <option value={""} hidden={true}>{props.i18nSelectAnOption}</option>
                    {/* {props.requestType !== "Withdraw" ? (
                      <option value={"ACH"}>{"Bank Transfer"}</option>
                    ) : (<></>)} */}
                    {props.locations.filter(l=>l.cashEnabled).length ? (
                      <option value="Cash">{props.i18nCash}</option>
                    ) : (<></>)}
                  </select>
                </div>
              </div>
            </>) : (<>
              {!props.cpfConfirmed ?(
                <div className="fieldRow">
                  <CashierBankIcon w={55} h={55} v={"8 5 55 55"} />
                  <div className="column text">
                    <div>CPF</div>
                    <input id="CPF"
                      value={props.cpf}
                      onChange={(e)=>handlePix(e, props.setCpf)}
                      inputMode="numeric"
                      required={true}
                    />
                  </div>
                </div>
              ) : (<></>)}
              {props.requestType=="Withdraw" ? (<>
                <div className="fieldRow">
                  <CashierBankIcon w={55} h={55} v={"8 5 55 55"} />
                  <div className="column text">
                    <div>Pix Key Type</div>
                    <select id="pixKeyType"
                      name="pixKeyType"
                      value={props.pixKeyType}
                      onChange={(e)=>{props.setPixKeyType(e.target.value)}}
                      ref={pixKeyTypeDropdown}
                      required={true}
                    >
                      <option value={"CPF"}>CPF</option>
                      {props.cpfConfirmed ? (<>
                        <option value={"EMAIL"}>Email</option>
                        <option value={"PHONE"}>Phone</option>
                        <option value={"EVP"}>EVP</option>
                      </>) : (<></>)}
                    </select>
                  </div>
                </div>
              </>) : (<></>)}
              {props.requestType=="Withdraw" && props.cpfConfirmed ? (
                <div className="fieldRow">
                  <CashierBankIcon w={55} h={55} v={"8 5 55 55"} />
                  <div className="column text">
                    <div>Pix Key</div>
                    <input id="pixKey"
                      value={props.pixKey}
                      onChange={(e)=>handlePix(e, props.setPixKey)}
                      inputMode={
                        props.pixKeyType==="CPF" ? "numeric"
                        : props.pixKeyType==="EMAIL" ? "email"
                        : props.pixKeyType==="PHONE" ? "tel"
                        : "text"
                      }
                      required={true}
                    />
                  </div>
                </div>
              ) : (<></>)}
            </>)}
            {props.transactionType==="Cash" ? (
              <div className="fieldRow">
                <CashierLocationIcon w={55} h={55} v={"8 5 55 55"} />
                <div className="column text">
                  <div>{props.i18nLocation}</div>
                  <select id="location"
                    name="location"
                    value={JSON.stringify(props.selectedLocation)}
                    onChange={handleLocationSelect}
                    ref={locationDropdown}
                    required={true}
                    placeholder={props.i18nSelectAnOption}
                  >
                    <option value={null} hidden={true}>
                      {props.i18nSelectAnOption}
                    </option>
                    {process.env?.REACT_APP_VENUE_PATH ? (
                      <option value={JSON.stringify(props.selectedLocation)}>
                        {props.selectedLocation?.name ?? ""}
                      </option>
                    ) : props.jogo ? (
                      <option value={JSON.stringify({id: 41, name: "Pix"})}>
                        Pix
                      </option>
                    ) : props.locations.filter(l=>l.cashEnabled).map((l,i)=>(
                      <option key={`venue${i}`} value={JSON.stringify(l)}>
                        {l.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            ) : (<></>)}
            <div className={`cashierCenter${props.transactionType=="Pix" ? " verifyMessageText" : ""}`}>
              {props.i18nVerifyMsg}
            </div>
          </div>
        )}</div>
        {props.requestType==="Withdraw" && props.transactionType==="ACH" ? (
          <div className="cashierCenter">
            Your withdrawal will be credited to your bank account within 2-3 business days.
          </div>
        ) : (<></>)}
        <div>
          {props.transactionType=="Pix" && props.requestType ? (
            <div className="pixLogo">
              <img src={pixLogo}/>
            </div>
          ) : (<></>)}
          <div 
            onClick={props.handleClick}
            className={`cashierButton ${props.requestType && !props.isValid ? "disabled" : ""} ${props.requestType ? "submit" : ""}`
            }
          >
            {!props.requestType ? props.i18nReturnHome : props.i18nContinue}
          </div>
        </div>
      </div>
    </div>
  )
}