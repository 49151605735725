import { redirect } from "react-router-dom";


const Style: { [key: string]: React.CSSProperties } = {
    container: {
        width: "400px",
        height: "320px",

        paddingTop: "20px",
        paddingBottom: "20px",
        paddingLeft: "20px",
        paddingRight: "20px",

        display: "flex",
        flexDirection: "column",
        gap: "20px",

        borderRadius: "15px",

        background: "#111C44",
    },
    btn: {
        background: "#2D65C1",
        border: "none",
        borderRadius: "20px",
        color: "white",

        cursor: "pointer",

        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        gap: "3px",

        width: "89px",
        height: "32px"
    }
    
};

type Props = {
    username:string
}


export function NotesCard({
    username
}: Props) {
    function viewNote() {
        redirect("/super/users/user_123/notes");
    }

    function addNote() {
        alert("WIP add note");
    }


    return (
        <div style={Style.container}>
            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                <span style={{ fontSize: "20px", fontWeight: "700",  }}>Add a Note</span>
                <span style={{ color: "rgba(163, 174, 208, 1)", fontSize: "12px" }}>Add notes about this user for future reference.</span>
            </div>
            <textarea name="" id="" 
                style={{ backgroundColor: "rgba(245, 246, 250, 1)", color: "rgba(138, 146, 166, 1)" }}
                cols={30} rows={15}
                value={
                    `Poker is a family of comparing card games in which players wager over which hand is best according to that specific game's rules in ways similar to these rankings. While the earliest known form of the game was played with just 20 cards, today it is usually played with a standard deck, although in countries where short packs are common, it may be played with 32, 40 or 48 cards.[1] nds of betting.`
                }>
            </textarea>
            <div style={{ display: "flex", justifyContent: "right", gap: "10px"}}>
                {/* <button style={Style.btn} type="button" onClick={viewNote}>View Note</button> */}
                <a href={"/super/users/" + username + "/notes"}
                    style={{ ...Style.btn, textDecoration: "none", fontSize: "14px" }}>
                    View Note
                </a>


                <button style={Style.btn} type="button" onClick={addNote}>Add Note</button>
            </div>
        </div>
    );
};











