import { Breadcrumbs } from "../shared/Breadcrumbs"
import { Header } from "../shared/Header"
import { TransactionsTable } from "./TransactionsTable"

export const Transactions = () => {
  return (
    <div style={{
      display: "flex",
      flexDirection: "column",
      gap: "20px",
      paddingLeft: "10px",
      paddingRight: "10px",
    }}>
      {/* Breadcrumbs */}
      <Breadcrumbs text={"Pages / Transactions"}></Breadcrumbs>
      {/* Top row */}
      <Header title="Transactions"></Header>
      {/* Body */}
      <TransactionsTable></TransactionsTable>
    </div>
  )
}