import { useEffect, useRef, useState } from "react"
import { useAuthUser, useSignOut } from "react-auth-kit"
import { Route, Routes, useLocation, useNavigate } from "react-router-dom"
import { Dashboard } from "./components/dashboard/Dashboard"
import { Games } from "./components/games/Games"
import { Users } from "./components/Users/Users"
import { Transactions } from "./components/Transactions/Transactions"
import { AdminSideBar } from "./components/shared/AdminSideBar"
import { Body } from "./components/shared/Body"
import { IndividualUser } from "./components/Users/IndividualUser/IndividualUser"
import { UserNotes } from "./components/Users/IndividualUser/Notes/UserNotes"

export const SuperAdminPanel = () => {
  const auth = useAuthUser()
  const navigate = useNavigate()
  const location = useLocation()
  const signOut = useSignOut();

  // "bardice100" || "jogo" || "mecca100" || "potodice100" || "keno414" || "javajackpot" || "qdice100" || "misDados" || ""
  const gameurl = useRef("");

  useEffect(() => {
    if (auth()?.id) {
      if (!auth()?.locations.includes(0)) { navigate("/", { replace: true, state: {} }) }
    } else {
      navigate("/login", { state: { redirect: location.pathname } })
    }
  }, [auth()?.id])

  // const game = urlSections[urlSections.length - 1];
  // let title = "";
  // switch (game) {
  //   case "bardice100":
  //     title = "Bar Dice 100";
  //     break;
  //   case "jogo":
  //     title = "Jogo Do Dado";
  //     break;
  //   case "mecca100":
  //     title = "Mecca 100";
  //     break;
  //   case "potodice100":
  //     title = "Potawatomi Dice 100";
  //     break;
  //   case "keno414":
  //     title = "Keno 414";
  //     break;
  //   case "javajackpot":
  //     title = "Java Jackpot";
  //     break;
  //   case "qdice100":
  //     title = "Q-Dice 100";
  //     break;
  //   case "misDados":
  //     title = "Mis Dados";
  //     break;
  //   default:
  //     break;
  // }

  return (
    <div id="super-admin">
      <AdminSideBar gameUrlRef={gameurl}/>
      <Body>
        <Routes>
          <Route path="/" element={
            <Games gameUrlRef={gameurl}/>
          } />
          <Route path="/:game/" element={
            <Dashboard gameUrlRef={gameurl}/>
          } />

          <Route path="/:game/users" element={
            <Users gameUrlRef={gameurl} />
          } />
          <Route path="/:game/users/:username" element={
            <IndividualUser />
          } />
          <Route path="/:game/users/:username/notes" element={
            <UserNotes />
          } />

          <Route path="/:game/transactions/*" element={
            <Transactions />
          } />
        </Routes>
      </Body>


      {/* <div className="SA-right">
        
      </div> */}
    </div>
  )
}