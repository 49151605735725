import { useState } from "react";
import { ContactInfoModal } from "./ContactInfoModal";


const Style: { [key: string]: React.CSSProperties } = {
    container: {
        width: "552px",
        height: "320px",

        paddingTop: "20px",
        paddingBottom: "20px",
        paddingLeft: "20px",
        paddingRight: "20px",

        display: "flex",
        flexDirection: "column",

        borderRadius: "15px",

        background: "#111C44",
    },
    contentsRow: {
        display: "flex",
        // border: "red solid 2px",
        // justifyContent: "space-between",
        gap: "20px"
        // alignItems: "space-around"
    },
    rowItemGroup: {
        flexGrow: "1",
        // maxWidth: "40%",
        padding: "20px",
        borderRadius: "20px",
        border: "none",
        background: "rgb(27, 37, 75)",
    },
    bigPrint: {
        fontSize: "14px",
        color: "rgb(163, 174, 208)",
        margin: "0px"
    },
    tinyPrint: {
        fontSize: "16px",
        color: "white",
        margin: "0px"
    }
};

type Props = {
    firstName: string,
    lastName: string,
    email: string,
    updatedAt: string,
}


export function ContactInfoCard({
    firstName,
    lastName,
    email,
    updatedAt,
}: Props) {

    const [showModal, setShowModal] = useState<Boolean>(false);

    const toggleModal = () => {
        setShowModal(!showModal);
    }

    function editPenClick() {
        setShowModal(true);
    }

    return (
        <div style={Style.container}>
            {/* Title, Pen button */}
            <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "20px" }}>
                <span style={{ fontSize: "21px", fontWeight: "700" }}>Contact Information</span>
                <img src={require("../../../assets/pen.png")}
                    onClick={editPenClick}
                    alt="Pen image"
                    style={{
                        width: "20px",
                        height: "20px",
                        cursor: "pointer"
                    }} />
                {
                    showModal ?
                        <ContactInfoModal title="Contact Information" btnRightLabel={"SAVE"} btnRightOnClick={toggleModal}
                            fname={firstName} lname={lastName} email={email}>
                        </ContactInfoModal>
                        :
                        <></>
                }
            </div>
            {/* Cards */}
            <div style={{
                display: "flex", flexDirection: "column", flexGrow: "1",
                gap: "20px",
                // justifyContent: "space-around"
            }}>
                {/* Row 1 */}
                <div style={Style.contentsRow}>
                    <div style={Style.rowItemGroup}>
                        <p style={Style.bigPrint}>First Name</p>
                        <p style={Style.tinyPrint}>{firstName}</p>
                    </div>
                    <div style={Style.rowItemGroup}>
                        <p style={Style.bigPrint}>Last Name</p>
                        <p style={Style.tinyPrint}>{lastName}</p>
                    </div>
                </div>
                {/* Row 2 */}
                {/* <div style={Style.contentsRow}> */}
                    <div style={Style.rowItemGroup}>
                        <p style={Style.bigPrint}>Email</p>
                        <p style={{ ...Style.tinyPrint, display: "flex", justifyContent: "space-between" }}>
                            {email}
                            <img src={require("../../../assets/lock.png")}
                                alt="Pen image"
                                style={{
                                    width: "15px",
                                    height: "19px",
                                    paddingLeft: "2px",
                                    paddingRight: "2px"
                                }} />
                        </p>
                    </div>
                    <div style={Style.rowItemGroup}>
                        <p style={Style.bigPrint}>Last Seen (UTC)</p>
                        <p style={Style.tinyPrint}>{ (new Date(updatedAt)).toUTCString() }</p>
                    </div>
                {/* </div> */}
            </div>
        </div>
    );
};











