import { Route, Routes } from "react-router-dom";
import { BarDiceController } from "./controller/BarDiceController";
import { BarDiceTerminal } from "./terminal/BarDiceTerminal";
import { BarDiceLogin } from "./login/BarDiceLogin";
import { io } from "socket.io-client";
import { useRef, useEffect, useState } from "react";
import { JogoDoDado } from "./controller/JogoDoDado";
import { BarDiceNav } from "./controller/components/BarDiceNav";
import { useAuthUser } from "react-auth-kit";

export const backend =
  process.env.REACT_APP_NODE_ENV === "production"
    ? process.env.REACT_APP_PROD // TODO: dedicated DB server
    : process.env.REACT_APP_NODE_ENV === "staging"
    ? process.env.REACT_APP_STAGING
    : "http://localhost:4000";

export const BarDice100 = (props) => {
  const auth = useAuthUser()
  const socket = useRef(null);

  // GAMESTATE OBJECT
  const [startTime, setStartTime] = useState(0);
  const [phase, setPhase] = useState("");
  const [players, setPlayers] = useState([]);
  const [busted, setBusted] = useState(0);
  const crashpoint = useRef(null)
  const [multiplier, setMultiplier] = useState("1.00");
  const [timeElapsed, setTimeElapsed] = useState(0);
  const [timeRemaining, setTimeRemaining] = useState(0);
  const [history, setHistory] = useState([""]);
  const [ping, setPing] = useState(0);
  const [jackpot, setJackpot] = useState(0)

  // ESTABLISH SOCKET CONNECTION
  useEffect(() => {
    if (auth()?.id) {
      socket.current = io(backend + "/game");
      socket.current.on("ping", (timestamp) => {
        setPing(Date.now() - timestamp);
      });
      socket.current.on("gameState", (newState) => {
        setStartTime(newState.startTime);
        setPhase(newState.phase)
        setPlayers(newState.players);
        setBusted(newState.busted);
        setMultiplier(newState.multiplier);
        setHistory(newState.history);
      });
      socket.current.on("start", (newStartTime) => {
        setStartTime(newStartTime);
      });
      socket.current.on("crashpoint", (newCrashpoint) => {
        crashpoint.current = newCrashpoint
      });
      socket.current.on("balance", (newBalance) => {
        props.setBalance(newBalance / 100);
      });
      socket.current.on("players", (newPlayers) => {
        setPlayers(newPlayers);
      });
      socket.current.on("history", (newHistory) => {
        setHistory(newHistory);
      });
      socket.current.on(`${props.jogo?"JogoDoDado":"BarDice100"}Jackpot`, (newTotal) => {
        setJackpot(newTotal);
        // props.setJackpotValue(newTotal)
        localStorage.setItem("jackpot", newTotal)
        console.log("jackpot", newTotal)
      });
      socket.current.emit("jackpot", `${props.jogo?"JogoDoDado":"BarDice100"}Jackpot`)

      return () => {
        socket.current?.disconnect();
      };
    }
  }, [auth()?.id]);

  // GAMESTATE UPDATE
  const [tick, setTick] = useState(0);
  const value = useRef(0);

  useEffect(() => {
    value.current = 0;
    if (startTime) {
      const now = Date.now()
      if (startTime > now) {
        if (phase!=="preGame") {
          setPhase("preGame")
          setMultiplier("1.00")
          setBusted(0)
          setTimeElapsed(0)
          crashpoint.current = null
        }
        setTimeRemaining(((startTime-now)/1000).toFixed(1))
      } else if (startTime <= now && phase!=="postGame" && crashpoint.current) {
        if (phase!=="inGame") {setPhase("inGame")}
        setTimeElapsed((now-startTime)/1000)
        value.current = Math.floor(
          Math.E**(Math.log(100)/30*timeElapsed) * 100
        );
        if (value.current >= crashpoint.current) {
          setBusted((crashpoint.current / 100).toFixed(2))
          setMultiplier((crashpoint.current / 100).toFixed(2))
          setPhase("postGame")
        } else if (value.current > parseFloat(multiplier)*100) {
          setMultiplier((value.current / 100).toFixed(2))
        }
      } else if (phase==="postGame" && !busted) {
        setBusted((crashpoint.current/100).toFixed(2))
        setMultiplier((crashpoint.current/100).toFixed(2))
      }
    }
    setTimeout(()=>{setTick(tick+1)}, 30)
    // eslint-disable-next-line
  }, [tick]);
  setTimeout(() => {
    setTick(tick + 1);
  }, 15);
  
  return (
    <Routes>
      <Route path="/login" element={
        <BarDiceLogin 
          backend={backend} 
          demo={props.demo} 
          jogo={props.jogo}
          ip={props.ip}
          selectedLocation={props.selectedLocation}
        />
      }/>
      <Route path="/" element={!props.jogo ?
        (<BarDiceController
          user={props.user}
          setUser={props.setUser}
          balance={props.balance}
          setBalance={props.setBalance}
          backend={backend}
          phase={phase}
          timeRemaining={timeRemaining}
          players={players}
          startTime={startTime}
          socket={socket}
          multiplier={multiplier}
          selectedLocation={props.selectedLocation}
          demo={props.demo}
          toasts={props.toasts}
          setToasts={props.setToasts}
          jackpot={jackpot}
          setJackpot={setJackpot}
          history={history}
          modalOpen={props.modalOpen}
          modalData={props.modalData}
          setModalOpen={props.setModalOpen}
          setModalData={props.setModalData}
          ip={props.ip}
        />) : (
          <JogoDoDado
            user={props.user}
            setUser={props.setUser}
            balance={props.balance}
            setBalance={props.setBalance}
            backend={backend}
            phase={phase}
            timeRemaining={timeRemaining}
            players={players}
            startTime={startTime}
            socket={socket}
            multiplier={multiplier}
            selectedLocation={props.selectedLocation}
            demo={props.demo}
            jogo={props.jogo}
            busted={busted}
            timeElapsed={timeElapsed}
            history={history}
            currency={props.currency}
            jackpot={jackpot}
            modalOpen={props.modalOpen}
            modalData={props.modalData}
            setModalOpen={props.setModalOpen}
            setModalData={props.setModalData}
            toasts={props.toasts}
            setToasts={props.setToasts}
            ip={props.ip}
          />
        )
      }/>
      <Route path="/*" element={
        <BarDiceTerminal
          user={props.user}
          setUser={props.setUser}
          phase={phase}
          timeRemaining={timeRemaining}
          timeElapsed={timeElapsed}
          players={players}
          multiplier={multiplier}
          history={history}
          busted={busted}
          selectedLocation={props.selectedLocation}
          setSelectedLocation={props.setSelectedLocation}
          demo={props.demo}
          jogo={props.jogo}
          jackpot={jackpot}
          ip={props.ip}
          backend={backend}
        />
      }/>
    </Routes>
  );
};
