
import { useState } from "react";

const Style: { [key: string]: React.CSSProperties } = {
    container: {
        width: "100vw",
        height: "100vh",
        top: "0",
        bottom: "0",
        left: "0",
        right: "0",
        position: "fixed",
        // background: "rgba(49,49,49,0.8)",
        backdropFilter: "blur(5px)",
        zIndex: "3",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    modalCard: {
        paddingLeft: "20px",
        paddingRight: "20px",
        paddingTop: "20px",
        paddingBottom: "20px",

        border: "solid rgb(197, 197, 197) 1px",
        borderRadius: "20px",

        width: "549px",
        // minWidth: "359px",

        display: "flex",
        flexDirection: "column",
        gap: "20px",

        zIndex: "4",
        color: "rgb(197, 197, 197, 1)"
    },
    rowItemGroup: {
        flexGrow: "1",
        // width: "100%",
        // maxWidth: "40%",
        padding: "20px",
        borderRadius: "20px",
        border: "none",
        background: "rgb(27, 37, 75)",
    },
    modalCloseBtn: {
        padding: "2px 4px",
        fontSize: "10px",
        border: "none",
        borderRadius: "20px",
        color: "white",
        width: "124px",
        height: "28px"
    },
    bigPrint: {
        fontSize: "14px",
        color: "rgb(163, 174, 208)",
        margin: "0px"
    },
    input: {
        backgroundColor: "transparent",
        color: "white",
        border: "none",
        width: "509px"
    }
};

type Props = {
    bgColor?: string,
    title: string,
    btnRightLabel: string,
    btnRightOnClick: Function,
    fname: string,
    lname: string,
    email: string,
}

export const ContactInfoModal = ({
    bgColor = "rgb(17, 28, 68, 1)",
    title,
    btnRightLabel,
    btnRightOnClick,
    fname,
    lname,
    email
}: Props) => {

    



    return (
        <>
            <div style={Style.container}>
                <div style={{ ...Style.modalCard, background: bgColor, }}>
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <h2>{title}</h2>
                    </div>
                    {/* <div> */}
                        <div style={Style.rowItemGroup}>
                            <h5 style={Style.bigPrint}>First Name</h5>
                            <input style={Style.input} type="text" name="" id=""
                                defaultValue={fname}/>
                        </div>
                        <div style={Style.rowItemGroup}>
                            <h5 style={Style.bigPrint}>Last Name</h5>
                            <input style={Style.input} type="text" name="" id=""
                                defaultValue={lname}/>

                        </div>
                        <div style={Style.rowItemGroup}>
                            <h5 style={Style.bigPrint}>Email</h5>
                            <input style={Style.input} type="text" name="" id=""
                                defaultValue={email}/>
                        </div>
                    {/* </div> */}
                    <div style={{ display: "flex", justifyContent: "right" }}>
                        <button style={{ ...Style.modalCloseBtn, background: "rgb(45, 101, 193)" }} onClick={() => btnRightOnClick()}>
                            {btnRightLabel}
                        </button>
                    </div>
                </div>
            </div >

        </>

    );

};