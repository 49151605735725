import { useState } from "react";
import { useAuthHeader, useAuthUser } from "react-auth-kit";
import { BarDiceNav } from "../../games/BarDice100/controller/components/BarDiceNav";
import "./_account.scss";
import { useLocation, useNavigate } from "react-router-dom";
import { BackButton } from "../../assets/icons/BackButton";

// SVG Icon assets
import { MailIcon } from "./assets/MailIcon";
import { ChatIcon } from "./assets/ChatIcon";

// Translations assets
import { i18nEn, i18nPt } from "./assets/i18n";

// Helper functions
import { isValidEmail } from "../_assets/helpers";
import { fetcher } from "../../utils/fetcher";

const Style = {
  error: {
    border: "#E8C305 solid 2px", // branding yellow
  },
  valid: {

  },
  rowSpaceBetween: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between"
  },
  container: {
    display: "flex",
    flexDirection: "column",
    // justifyContent: "space-around",
    // alignContent: "center",
    width: "90%",
    maxWidth: "450px",
    maxHeight: "1000px",
    margin: "0px auto",
    paddingBottom: "1rem",
    // border: "white solid 3px"
  },
  navbar: {
    marginTop: "1rem",
    marginBottom: "1rem"
    // display: "flex",
    // flexDirection: "row",
  },
  page: {
    display: "flex",
    flexDirection: "column",
    flexGrow: "1",
    justifyContent: "space-between",
    // border: "white solid 3px"
  },
  intro: {
    display: "flex",
    flexDirection: "column",
    alignItems: "stretch",
    marginTop: "1rem",
    // border: "white solid 3px"
  },
  introBackBtn: {
    flexGrow: 9,
    // border: "white solid 3px"
  },
  introTitle: {
    fontFamily: "Open Sans",
    fontWeight: "700",
    fontSize: "22px",
    textAlign: "center",
    color: "#ffffff",
    textShadow: "12px 16px 22px rgba(22, 25, 97, 0.33)",
    flexGrow: 0,
    // border: "white solid 3px"
  },
  introRightSpace: {
    flexGrow: 10,
    // border: "white solid 3px"
  },
  introText: {
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "18px",
    textAlign: "center",
    margin: "1rem",
    color: "#c5c5c5",
    // border: "white solid 3px"
  },
  fieldGroup: {
    marginBottom: "1rem",
    display: "flex",
    flexDirection: "column",
    // border: "white solid 3px",
  },
  fieldGroupLabel: {
    // display: "flex",
    color: "#ffffff",
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "13px",
    textAlign: "end",
    // border: "white solid 3px",
  },
  fieldGroupBox: {
    display: "flex",
    // alignItems: "center",
    background: "#181c27",
    border: "2px solid #42404b",
    borderRadius: "6px",
    padding: "1rem",
    margin: "0.2rem 0 0 0", // TRBL
  },
  fieldGroupEmail: {
    display: "flex",
    flexGrow: "1",
    background: "#181c27",
    border: "none",
    borderRadius: "6px",
    // marginTop: "0.5rem",
    marginLeft: "0.5rem",
    fontFamily: "Open Sans",
    fontWeight: "400",
    fontSize: "12px",
    color: "#c5c5c5",
  },
  fieldGroupTextarea: {
    display: "flex",
    flexGrow: "1",
    minHeight: "140px",
    background: "#181c27",
    border: "none",
    borderRadius: "6px",
    marginLeft: "0.5rem",
    lineHeight: "80%",
    fontFamily: "Open Sans",
    fontWeight: "400",
    fontSize: "12px",
    color: "#c5c5c5",
  },
  bottomBtn: {
    display: "flex",
    height: "4rem",
    flexShrink: "0",
    justifyContent: "center",
    alignItems: "center",

    background: "#2d65c1",
    border: "2px solid #42404b",
    borderRadius: "6px",

    fontFamily: "Open Sans",
    fontWeight: "600",
    fontSize: "20px",
    color: "#c5c5c5",
  },
  blur: {
    filter: "blur(2px)"
  }
};

export const Support = (props) => {
  const auth = useAuthUser();
  const authHeader = useAuthHeader();
  const navigate = useNavigate();
  const location = useLocation();

  const i18n = props.jogo ? i18nPt: i18nEn;
  // const i18n = props.jogo ? i18nEn: i18nPt;
  
  // State<Object> that maps to all form fields
  const [form, setForm] = useState({
    email: auth().email || "",
    message: "",
  });
  const [formValidation, setFormValidation] = useState({
    // in case auth() returns a value for email, must validate immediately
    // otherwise must trigger onChange for true despite initial value being correct
    email: isValidEmail(form.email),
    message: false,
  })

  // onChange handler for every field in form (every field must have name attr)
  const handleChange = event => {
    // console.log(`Field ${event.target.name} has been changed; new value is '${event.target.value}'`)
    // Update state with new values
    setForm({
      ...form,
      [event.target.name]: event.target.value,
    });
    // Validate new values - if false, change className to error (same as validate section of handleBlur)
    let result = false;
    switch (event.target.name) {
      case "email":
        result = isValidEmail(event.target.value);
        break;
      case "message":
        result = event.target.value.trim().length > 0;
        break;
      default:
        console.log("Unexpected behavior occured in Support.js:handleChange");
        break;
    }
    setFormValidation({
      ...formValidation,
      [event.target.name]: result,
    });
    // console.log(`New value is ${result==false? "INVALID" : "VALID"}`);
    // Note: Toggle blur and disable on Submit button is done on rerender inline
  }

  const onSubmit = async (e) => {
    // console.log(`Submitting {email:${form.email}, message:${form.message}}`);
    await fetcher("POST", authHeader(), props.backend.current+"/email/send", {
      host: window.location.host,
      to: process.env.REACT_APP_SUPPORTEMAIL,
      subject: "Support Request: " + form.email,
      text: form.message,
    }).then(() => {
      navigate("/account", {replace: true, state: {}})
      console.log("support email sent")
    }).catch(e=>{console.log("email send error", e)});
  };

  const onBack = (e) => {
    navigate("/account");
  };

  return (
    <div style={Style.container}>
      <section style={Style.navbar}>
        <BarDiceNav
          balance={props.balance}
          user={props.user}
          selectedLocation={props.selectedLocation}
          bet={0}
          demo={props.demo}
          jogo={props.jogo}
          jackpot={props.jackpotValue}
          modalOpen={props.modalOpen}
          modalData={props.modalData}
          setModalOpen={props.setModalOpen}
          setModalData={props.setModalData}
        />
      </section>
      
      <section style={Style.page}>
      <div style={Style.intro}>
        <div style={Style.rowSpaceBetween}>
          <div onClick={onBack} style={Style.introBackBtn} alt={i18n.back}>
            <BackButton size={15} />
          </div>
          <div style={Style.introTitle}>{i18n.title}</div>
          <div style={Style.introRightSpace}>
            {/* Negative space on the right */}
          </div>
        </div>

        <div style={Style.introText}>
          {i18n.intro1}
          <br></br>
          {i18n.intro2}
        </div>
      </div>

      <div style={Style.fieldGroup}>
        <section style={{
          ...Style.fieldGroupBox, 
          ...formValidation.email ? Style.valid : Style.error
          }}>
          <MailIcon size={19} />
          <input
            id="email"
            type="email"
            name="email"
            placeholder={i18n.email}
            value={form.email} // So that the auth().email autofills here if present, but still can be overwritten
            onChange={handleChange}
            style={Style.fieldGroupEmail}
          />
        </section>
        <label style={Style.fieldGroupLabel} htmlFor="email">
          *{i18n.required}
        </label>
      </div>

      <div style={Style.fieldGroup}>
        <section style={{
          ...Style.fieldGroupBox, 
          ...formValidation.message ? Style.valid : Style.error
          }}>
          <ChatIcon size={19} />
          <textarea
            id="message"
            type="text"
            name="message"
            placeholder={i18n.message}
            value={form.message}
            onChange={handleChange}
            style={Style.fieldGroupTextarea}
          />
        </section>
        <label style={Style.fieldGroupLabel} htmlFor="message">
          *{i18n.required}
        </label>
      </div>


      <button
        style={{
          ...Style.bottomBtn,
          ...!(formValidation.email && formValidation.message) ? Style.blur : null
        }}
        onClick={onSubmit}
        disabled={!(formValidation.email && formValidation.message)}>
        {i18n.bottomBtn}
      </button>
      </section>
      

      {/* 
        TOAST text:
        en - Message Submitted
        pt - Mensagem enviada
      */}
    </div>
  );
};

