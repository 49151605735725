import { replacePlaceholders } from "../../utils/replacePlaceholders"
import { i18nEn, i18nPt } from "./assets/i18n"
import { useLocation, useNavigate } from "react-router-dom"

export const Scan = (props) => {
  const i18n = props.jogo ? i18nPt : i18nEn
  const navigate = useNavigate()
  const location = useLocation()

  return (
    <div id="error">
      <div className="errorFrame">
        <div className="errorLogo">
        </div>
        <div className="errorHeader">{i18n.scanHeader}</div>
        <div className="errorBody">
          <ol>
            <li>{replacePlaceholders(i18n.scanLine1, [document.title])}</li>
            <li>{i18n.scanLine2}</li>
          </ol>
        </div>
      </div>
    </div>
  )
}