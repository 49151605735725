export const WifiLogo = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" 
      width={props?.w ?? "103"} 
      height={props?.h ?? "102"} 
      viewBox={props?.v ?? "0 0 103 102"} 
      fill="none">
      <path d="M87.0746 36.2488C90.6791 45.2122 90.8348 55.1934 87.5115 64.2649C84.1883 73.3364 77.6219 80.855 69.0801 85.3689C60.5384 89.8828 50.6271 91.0718 41.2601 88.7065C31.893 86.3411 23.7345 80.5891 18.3599 72.561C12.9852 64.533 10.7756 54.7983 12.1577 45.2365C13.5397 35.6748 18.4154 26.9642 25.8434 20.7867C33.2713 14.6091 42.7247 11.4027 52.3781 11.7866C62.0316 12.1704 71.2005 16.1173 78.1145 22.8652" stroke="#535353" strokeWidth="2"/>
      <path d="M61.9846 30.6V13.3385L53.9999 12.5H47.4999L40.8 14.1231L36.0923 15.6923L29.0307 19.6154L23.5384 24.3231L18.8307 29.0308L14.9999 37L40.0153 64.3385V72.1847L46.2923 76.1077V88.6616H56.4923L63.5538 87.0923L72.1846 83.1693V72.1847L69.8307 69.0462H61.9846V54.9231L60.4153 52.5693H36.0923V44.7231H46.2923V34.5231H58.8461L61.9846 30.6Z" fill="#535353" stroke="#535353" strokeWidth="0.5"/>
      <path d="M81.1403 19.648C82.5874 18.4247 84.2301 17.5672 85.9516 17.0754M93.0004 17.0654C94.7347 17.5554 96.3901 18.4163 97.8471 19.648" stroke="#535353" strokeWidth="1.8" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M89.4462 22.2974L89.4932 7.84613" stroke="#535353" strokeWidth="1.8" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M76.8923 15.3728C79.5524 12.993 82.7053 11.4956 85.9854 10.8805M93.098 10.9173C96.3094 11.5541 99.3916 13.0392 102 15.3728M85.3288 23.9352C85.609 23.7331 85.9 23.554 86.1994 23.3978M92.6476 23.3683C92.9671 23.5313 93.2774 23.7203 93.5754 23.9352" stroke="#535353" strokeWidth="1.8" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M89.446 29.5229C90.4071 29.5229 91.1863 28.7141 91.1863 27.7165C91.1863 26.7188 90.4071 25.9101 89.446 25.9101C88.4848 25.9101 87.7057 26.7188 87.7057 27.7165C87.7057 28.7141 88.4848 29.5229 89.446 29.5229Z" fill="#535353" stroke="#535353" strokeWidth="1.8" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  )
}