import React from "react";

export const BackButton = ({ size, color=null }) => {
  return (
    <svg
      width={size ? size : 9}
      height={size ? size / 0.692 : 13}
      viewBox="0 0 9 13"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.25687 13C7.6018 13.0023 7.93957 12.9039 8.22686 12.7174C8.51416 12.5309 8.73788 12.2649 8.86934 11.9534C9.0008 11.6419 9.034 11.2992 8.96469 10.9691C8.89537 10.6391 8.72671 10.3368 8.48032 10.101L4.75391 6.4805L8.48032 2.8439C8.78613 2.52333 8.95262 2.09934 8.94471 1.66123C8.9368 1.22313 8.7551 0.805135 8.43789 0.495302C8.12069 0.185469 7.69275 0.00799325 7.24422 0.000263461C6.7957 -0.00746632 6.36161 0.155154 6.03342 0.453864L0.0975456 6.2518C0.0666367 6.28175 0.0421038 6.31737 0.0253617 6.35662C0.0086197 6.39588 0 6.43798 0 6.4805C0 6.52302 0.0086197 6.56512 0.0253617 6.60438C0.0421038 6.64363 0.0666367 6.67925 0.0975456 6.7092L6.03342 12.5071C6.35853 12.8229 6.7984 13.0001 7.25687 13Z"
        fill={color ?? "#C5C5C5"}
      />
    </svg>
  );
};
