import { replacePlaceholders } from "../../utils/replacePlaceholders"
import { WifiLogo } from "./components/WifiLogo"
import { i18nEn, i18nPt } from "./assets/i18n"
import { useLocation, useNavigate } from "react-router-dom"
import { useEffect, useState } from "react"

export const Wifi = (props) => {
  const i18n = props.jogo ? i18nPt : i18nEn
  const navigate = useNavigate()
  const location = useLocation()
  const ip = props.ip
  const selectedLocation = props.selectedLocation

  const [reconnect, setReconnect] = useState(false)

  useEffect(()=>{
    if (selectedLocation?.ips?.includes(ip)) {
      setReconnect(true)
      console.log("can reconnect")
    }
  }, [ip, selectedLocation])

  return (
    <div id="error">
      <div className="errorFrame">
        <div className="errorLogo">
          <WifiLogo/>
        </div>
        <div className="errorHeader">
          {replacePlaceholders(
            i18n.wifiHeader, 
            [props.selectedLocation?.wifiName ?? "<Wifi Name>"]
          )}
        </div>
        <div className="errorBody">
          <ol>
            <li>
              {replacePlaceholders(
                i18n.wifiLine1, 
                [document.title, props.selectedLocation?.wifiName ?? "<Wifi Name>"]
              )}
            </li>
            <li>
              {i18n.wifiLine2}
            </li>
          </ol>
        </div>
        <div 
          className={`errorButton${reconnect?"": " disabled"}`}
          disabled={reconnect}
          onClick={()=>{
            if (reconnect) navigate(
              location.state?.redirect ?? "/", {replace: true, state: {}}
            )
          }}
        >
          {i18n.wifiButton}
        </div>
      </div>
    </div>
  )
}