

const Style: { [key: string]: React.CSSProperties } = {
    container: {
        width: "500px",
        height: "340px",
        paddingBottom: "20px",
        display: "flex",
        flexDirection: "column",
        borderRadius: "15px",
        background: "rgba(17, 28, 68, 1)",
    },
    theadCell: {
        color: "rgba(163, 174, 208, 1)",
        textAlign: "left",
        fontSize: "14px",
        paddingLeft: "20px",
    },
    tbodyCell: {
        color: "white",
        textAlign: "left",
        fontSize: "14px",
        paddingTop: "10px",
        paddingLeft: "20px",
        paddingBottom: "10px",
    },
    modifyBtn: {
        background: "#2D65C1",
        border: "none",
        borderRadius: "20px",
        color: "white",

        cursor: "pointer",

        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        gap: "3px",

        width: "65px",
        height: "28px"
    },
};

type Props = {

}

type TableDataRow = {
    id: string,
    bet: string,
    guess: string,
    result: string,
    time: string
};





export function RecentBetsCard({

}: Props) {

    const downloadCSV = () => {
        const rows = [];
    
        function convertTableDataRowToArray(row: TableDataRow) {
            return [row.id, row.bet, row.guess, row.result, row.time];
        }
    
        for (let index = 0; index < tableDataBets.length; index++) {
            const eachTableDataRow = tableDataBets[index];
            rows.push(convertTableDataRowToArray(eachTableDataRow));
        }
    
        let csvContent = "data:text/csv;charset=utf-8,"
            + rows.map(e => e.join(",")).join("\n");
    
    
        var encodedUri = encodeURI(csvContent);
        window.open(encodedUri);
    }

    let tableDataBets: Array<TableDataRow> = [];

    // Fake data
    // let singleTestData = {
    //     id: "",
    //     bet: "$9.21",
    //     guess: "2.00x",
    //     result: "Win",
    //     time: "03:17:54"
    // };
    // for (let i = 0; i < 45; i++) {
    //     singleTestData.id = String(i);
    //     tableDataBets.push(singleTestData);
    // }

    let count = 0;
    const tableItems = tableDataBets.map((each) =>
        <tr key={each.id}>
            <td style={Style.tbodyCell}>{each.bet}</td>
            <td style={Style.tbodyCell}>{each.guess}</td>
            <td style={Style.tbodyCell}>{each.result}</td>
            <td style={Style.tbodyCell}>{each.time}</td>
        </tr>
    );


    return (
        <div style={Style.container}>
            {/* Header row - Recent Transactions title, + Button, CSV Button */}
            <div style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                paddingTop: "20px",
                paddingLeft: "20px",
                paddingRight: "20px",
                paddingBottom: "20px",
                background: "rgba(27, 37, 75, 1)",
                borderTopLeftRadius: "20px",
                borderTopRightRadius: "20px"
            }}>
                <span style={{ color: "white", fontSize: "20px", fontWeight: "700" }}>Recent Bets</span>
                <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                    <button type="button" style={Style.modifyBtn} onClick={downloadCSV}>
                        <img src={require("../../../assets/download.png")} alt="Download Icon" width={"15px"} height={"15px"} />
                        CSV
                    </button>
                </div>

            </div>
            {/* Table */}
            <div style={{
                overflowY: "scroll",
            }}>
                <table style={{
                    tableLayout: "fixed",
                    borderCollapse: "collapse",
                    width: "100%"
                }}>
                    <colgroup>
                        <col style={{ width: "25%" }}></col>
                        <col style={{ width: "25%" }}></col>
                        <col style={{ width: "25%" }}></col>
                        <col style={{ width: "25%" }}></col>
                    </colgroup>
                    <thead style={{background: "rgba(27, 37, 75, 1)",}}>
                        <tr>
                            <th style={Style.theadCell}>Bet</th>
                            <th style={Style.theadCell}>Guess</th>
                            <th style={Style.theadCell}>Result</th>
                            <th style={Style.theadCell}>Time (UTC)</th>
                        </tr>
                    </thead>
                    <tbody>
                        {tableItems}
                    </tbody>
                </table>
            </div>
        </div>
    );
};











