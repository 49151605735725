export const Spin07 = (props) => {
  return (
    <svg id="Layer_1" data-name="Layer 1" width={props?.w ?? "1000"} height={props?.h ?? "325"} viewBox={props?.v ?? "0 0 1000 325"}>
      <defs>
        <radialGradient id="radial-gradient" cx="499.29" cy="161.8" fx="499.29" fy="161.8" r="550.95" gradientTransform="translate(0 118.07) scale(1 .27)" gradientUnits="userSpaceOnUse">
          <stop offset=".63" stopColor="#f7af31"/>
          <stop offset=".72" stopColor="#f6aa2f"/>
          <stop offset=".81" stopColor="#f39e2a"/>
          <stop offset=".9" stopColor="#ef8923"/>
          <stop offset="1" stopColor="#ea6d18"/>
          <stop offset="1" stopColor="#ea6c18"/>
        </radialGradient>
        <linearGradient id="linear-gradient" x1="500.09" y1="38.79" x2="500.09" y2="271.59" gradientUnits="userSpaceOnUse">
          <stop offset="0" stopColor="#720f0d"/>
          <stop offset=".24" stopColor="#77100d"/>
          <stop offset=".52" stopColor="#85140f"/>
          <stop offset=".83" stopColor="#9e1a12"/>
          <stop offset="1" stopColor="#af1f14"/>
        </linearGradient>
        <linearGradient id="linear-gradient-2" x1="500.09" y1="280.62" x2="500.09" y2="48.83" gradientUnits="userSpaceOnUse">
          <stop offset="0" stopColor="#720f0d"/>
          <stop offset=".04" stopColor="#750f0d"/>
          <stop offset=".43" stopColor="#941810"/>
          <stop offset=".77" stopColor="#a81d13"/>
          <stop offset="1" stopColor="#af1f14"/>
        </linearGradient>
        <radialGradient id="radial-gradient-2" cx="757.39" cy="57.56" fx="757.39" fy="57.56" r="29.8" gradientTransform="translate(-1.64 .68)" gradientUnits="userSpaceOnUse">
          <stop offset=".15" stopColor="#fff"/>
          <stop offset=".21" stopColor="#fff" stopOpacity=".95"/>
          <stop offset=".32" stopColor="#fff" stopOpacity=".81"/>
          <stop offset=".47" stopColor="#fff" stopOpacity=".59"/>
          <stop offset=".66" stopColor="#fff" stopOpacity=".3"/>
          <stop offset=".83" stopColor="#fff" stopOpacity="0"/>
        </radialGradient>
        <radialGradient id="radial-gradient-3" cx="-1427.37" cy="3820.17" fx="-1427.37" fy="3820.17" r="26.6" gradientTransform="translate(4466.32 -1633.73) rotate(45)" gradientUnits="userSpaceOnUse">
          <stop offset="0" stopColor="#fff"/>
          <stop offset=".1" stopColor="#fff" stopOpacity=".98"/>
          <stop offset=".2" stopColor="#fff" stopOpacity=".94"/>
          <stop offset=".3" stopColor="#fff" stopOpacity=".86"/>
          <stop offset=".4" stopColor="#fff" stopOpacity=".75"/>
          <stop offset=".5" stopColor="#fff" stopOpacity=".61"/>
          <stop offset=".6" stopColor="#fff" stopOpacity=".43"/>
          <stop offset=".7" stopColor="#fff" stopOpacity=".23"/>
          <stop offset=".8" stopColor="#fff" stopOpacity="0"/>
        </radialGradient>
        <radialGradient id="radial-gradient-4" cx="800.07" cy="62.64" fx="800.07" fy="62.64" r="29.8" href="#radial-gradient-2"/>
        <radialGradient id="radial-gradient-5" cx="-1393.6" cy="3793.58" fx="-1393.6" fy="3793.58" r="26.6" href="#radial-gradient-3"/>
        <radialGradient id="radial-gradient-6" cx="842.46" cy="68.44" fx="842.46" fy="68.44" href="#radial-gradient-2"/>
        <radialGradient id="radial-gradient-7" cx="-1359.53" cy="3767.71" fx="-1359.53" fy="3767.71" href="#radial-gradient-3"/>
        <radialGradient id="radial-gradient-8" cx="160.98" cy="248.07" fx="160.98" fy="248.07" r="29.8" href="#radial-gradient-2"/>
        <radialGradient id="radial-gradient-9" cx="-1714.39" cy="4376.61" fx="-1714.39" fy="4376.61" href="#radial-gradient-3"/>
        <radialGradient id="radial-gradient-10" cx="203.99" cy="253.86" fx="203.99" fy="253.86" r="29.8" href="#radial-gradient-2"/>
        <radialGradient id="radial-gradient-11" cx="-1679.88" cy="4350.29" fx="-1679.88" fy="4350.29" r="26.6" href="#radial-gradient-3"/>
        <radialGradient id="radial-gradient-12" cx="246.06" cy="260" fx="246.06" fy="260" r="29.8" href="#radial-gradient-2"/>
        <radialGradient id="radial-gradient-13" cx="-1645.79" cy="4324.88" fx="-1645.79" fy="4324.88" r="26.6" href="#radial-gradient-3"/>
        <radialGradient id="radial-gradient-14" cx="843.83" cy="247.86" fx="843.83" fy="247.86" r="29.8" href="#radial-gradient-2"/>
        <radialGradient id="radial-gradient-15" cx="-1231.69" cy="3893.61" fx="-1231.69" fy="3893.61" r="26.6" href="#radial-gradient-3"/>
        <radialGradient id="radial-gradient-16" cx="800.8" cy="253.98" fx="800.8" fy="253.98" r="29.8" href="#radial-gradient-2"/>
        <radialGradient id="radial-gradient-17" cx="-1257.79" cy="3928.37" fx="-1257.79" fy="3928.37" r="26.6" href="#radial-gradient-3"/>
        <radialGradient id="radial-gradient-18" cx="759.03" cy="260.16" fx="759.03" fy="260.16" r="29.8" href="#radial-gradient-2"/>
        <radialGradient id="radial-gradient-19" cx="-1282.95" cy="3962.28" fx="-1282.95" fy="3962.28" r="26.6" href="#radial-gradient-3"/>
        <radialGradient id="radial-gradient-20" cx="246.98" cy="57.47" fx="246.98" fy="57.47" r="29.8" href="#radial-gradient-2"/>
        <radialGradient id="radial-gradient-21" cx="-1788.35" cy="4181.02" fx="-1788.35" fy="4181.02" href="#radial-gradient-3"/>
        <radialGradient id="radial-gradient-22" cx="204.44" cy="62.68" fx="204.44" fy="62.68" r="29.8" href="#radial-gradient-2"/>
        <radialGradient id="radial-gradient-23" cx="-1814.74" cy="4214.79" fx="-1814.74" fy="4214.79" href="#radial-gradient-3"/>
        <radialGradient id="radial-gradient-24" cx="162.88" cy="67.85" fx="162.88" fy="67.85" r="29.8" href="#radial-gradient-2"/>
        <radialGradient id="radial-gradient-25" cx="-1840.48" cy="4247.82" fx="-1840.48" fy="4247.82" r="26.6" href="#radial-gradient-3"/>
      </defs>
      <g>
        <path strokeWidth="0px" fill="#ffda3e" d="m500.09,313.83c-8.99,0-17.98-.62-26.9-1.86L45.9,252.75c-12.66-1.75-24.25-8.03-32.65-17.67C4.86,225.44.23,213.1.23,200.32v-83.93c0-26.79,20.06-49.39,46.66-52.56L476.89,12.55c15.4-1.84,31.01-1.84,46.41,0l429.99,51.29c26.6,3.17,46.66,25.77,46.66,52.56v83.93c0,12.78-4.62,25.13-13.02,34.76-8.39,9.64-19.99,15.91-32.65,17.67l-427.29,59.23c-8.93,1.24-17.91,1.86-26.9,1.86Zm0-296c-7.49,0-14.98.44-22.42,1.33L47.68,70.45c-23.25,2.77-40.78,22.52-40.78,45.94v83.93c0,22.97,17.16,42.67,39.91,45.82l427.29,59.23c17.24,2.39,34.73,2.39,51.97,0l427.29-59.23c22.75-3.15,39.91-22.85,39.91-45.82v-83.93c0-23.42-17.53-43.16-40.78-45.94l-429.99-51.29c-7.44-.89-14.93-1.33-22.42-1.33Z"/>
        <path strokeWidth="0px" fill="url(#radial-gradient)" d="m499.29,308.92c-8.92,0-17.84-.6-26.7-1.8L48.44,249.53c-12.57-1.71-24.08-7.81-32.41-17.17-8.33-9.37-12.92-21.37-12.92-33.8v-81.59c0-26.05,19.91-48.01,46.32-51.1L476.26,16.01c15.28-1.79,30.78-1.79,46.07,0l426.83,49.86c26.41,3.08,46.32,25.05,46.32,51.1v81.59c0,12.42-4.59,24.43-12.92,33.8-8.33,9.37-19.84,15.47-32.41,17.17l-424.15,57.58c-8.86,1.2-17.78,1.8-26.7,1.8Z"/>
        <path strokeWidth="0px" fill="url(#linear-gradient)" d="m500.09,281.01c-7.39,0-14.86-.53-22.18-1.57L61.38,220.21c-5.29-.75-10.14-3.44-13.65-7.58-3.51-4.13-5.44-9.43-5.44-14.91v-83.93c0-11.49,8.39-21.19,19.51-22.55l419.16-51.29c6.32-.77,12.76-1.17,19.13-1.17s12.81.39,19.13,1.17l419.16,51.29c11.12,1.36,19.51,11.05,19.51,22.55v83.93c0,5.48-1.93,10.78-5.44,14.91-3.51,4.13-8.36,6.83-13.65,7.58l-416.53,59.23c-7.32,1.04-14.79,1.57-22.18,1.57Z"/>
        <path strokeWidth="0px" fill="url(#linear-gradient-2)" d="m500.09,283.61c-7.39,0-14.86-.53-22.18-1.57L61.38,222.81c-5.29-.75-10.14-3.44-13.65-7.58-3.51-4.13-5.44-9.43-5.44-14.91v-83.93c0-11.49,8.39-21.19,19.51-22.55l419.16-51.29c6.32-.77,12.76-1.17,19.13-1.17s12.81.39,19.13,1.17l419.16,51.29c11.12,1.36,19.51,11.05,19.51,22.55v83.93c0,5.48-1.93,10.78-5.44,14.91-3.51,4.13-8.36,6.83-13.65,7.58l-416.53,59.23c-7.32,1.04-14.79,1.57-22.18,1.57Z"/>
        <g>
          <ellipse strokeWidth="0px" fill="#ffde3b" cx="501.16" cy="28.02" rx="12.32" ry="7.36"/>
          <ellipse strokeWidth="0px" fill="#fbf8b5" cx="501.16" cy="28.03" rx="6.31" ry="3.57"/>
        </g>
        <g>
          <ellipse strokeWidth="0px" fill="#ffde3b" cx="501.16" cy="295.67" rx="12.32" ry="7.36"/>
          <ellipse strokeWidth="0px" fill="#fbf8b5" cx="501.16" cy="295.68" rx="6.31" ry="3.57"/>
        </g>
        <g>
          <g>
            <ellipse strokeWidth="0px" fill="#ffde3b" cx="458.95" cy="32.06" rx="12.32" ry="7.36"/>
            <ellipse strokeWidth="0px" fill="#fbf8b5" cx="458.95" cy="32.06" rx="6.31" ry="3.57"/>
          </g>
          <g>
            <ellipse strokeWidth="0px" fill="#ffde3b" cx="416.35" cy="37.29" rx="12.32" ry="7.36"/>
            <ellipse strokeWidth="0px" fill="#fbf8b5" cx="416.35" cy="37.3" rx="6.31" ry="3.57"/>
          </g>
          <g>
            <ellipse strokeWidth="0px" fill="#ffde3b" cx="373.75" cy="42.53" rx="12.32" ry="7.36"/>
            <ellipse strokeWidth="0px" fill="#fbf8b5" cx="373.75" cy="42.54" rx="6.31" ry="3.57"/>
          </g>
          <g>
            <ellipse strokeWidth="0px" fill="#ffde3b" cx="331.15" cy="47.77" rx="12.32" ry="7.36"/>
            <ellipse strokeWidth="0px" fill="#fbf8b5" cx="331.15" cy="47.77" rx="6.31" ry="3.57"/>
          </g>
          <g>
            <ellipse strokeWidth="0px" fill="#ffde3b" cx="288.56" cy="53" rx="12.32" ry="7.36"/>
            <ellipse strokeWidth="0px" fill="#fbf8b5" cx="288.56" cy="53.01" rx="6.31" ry="3.57"/>
          </g>
          <g>
            <ellipse strokeWidth="0px" fill="#ffde3b" cx="245.96" cy="58.24" rx="12.32" ry="7.36"/>
            <ellipse strokeWidth="0px" fill="#fbf8b5" cx="245.96" cy="58.25" rx="6.31" ry="3.57"/>
          </g>
          <g>
            <ellipse strokeWidth="0px" fill="#ffde3b" cx="203.36" cy="63.48" rx="12.32" ry="7.36"/>
            <ellipse strokeWidth="0px" fill="#fbf8b5" cx="203.36" cy="63.48" rx="6.31" ry="3.57"/>
          </g>
          <g>
            <ellipse strokeWidth="0px" fill="#ffde3b" cx="160.76" cy="68.71" rx="12.32" ry="7.36"/>
            <ellipse strokeWidth="0px" fill="#fbf8b5" cx="160.76" cy="68.72" rx="6.31" ry="3.57"/>
          </g>
          <g>
            <ellipse strokeWidth="0px" fill="#ffde3b" cx="118.16" cy="73.95" rx="12.32" ry="7.36"/>
            <ellipse strokeWidth="0px" fill="#fbf8b5" cx="118.16" cy="73.96" rx="6.31" ry="3.57"/>
          </g>
          <g>
            <ellipse strokeWidth="0px" fill="#ffde3b" cx="75.56" cy="79.19" rx="12.32" ry="7.36"/>
            <ellipse strokeWidth="0px" fill="#fbf8b5" cx="75.56" cy="79.19" rx="6.31" ry="3.57"/>
          </g>
        </g>
        <g>
          <g>
            <ellipse strokeWidth="0px" fill="#ffde3b" cx="542.33" cy="32.01" rx="12.32" ry="7.36"/>
            <ellipse strokeWidth="0px" fill="#fbf8b5" cx="542.33" cy="32.01" rx="6.31" ry="3.57"/>
          </g>
          <g>
            <ellipse strokeWidth="0px" fill="#ffde3b" cx="584.93" cy="37.24" rx="12.32" ry="7.36"/>
            <ellipse strokeWidth="0px" fill="#fbf8b5" cx="584.93" cy="37.25" rx="6.31" ry="3.57"/>
          </g>
          <g>
            <ellipse strokeWidth="0px" fill="#ffde3b" cx="627.53" cy="42.48" rx="12.32" ry="7.36"/>
            <ellipse strokeWidth="0px" fill="#fbf8b5" cx="627.53" cy="42.49" rx="6.31" ry="3.57"/>
          </g>
          <g>
            <ellipse strokeWidth="0px" fill="#ffde3b" cx="670.13" cy="47.72" rx="12.32" ry="7.36"/>
            <ellipse strokeWidth="0px" fill="#fbf8b5" cx="670.13" cy="47.72" rx="6.31" ry="3.57"/>
          </g>
          <g>
            <ellipse strokeWidth="0px" fill="#ffde3b" cx="712.73" cy="52.95" rx="12.32" ry="7.36"/>
            <ellipse strokeWidth="0px" fill="#fbf8b5" cx="712.73" cy="52.96" rx="6.31" ry="3.57"/>
          </g>
          <g>
            <ellipse strokeWidth="0px" fill="#ffde3b" cx="755.33" cy="58.19" rx="12.32" ry="7.36"/>
            <ellipse strokeWidth="0px" fill="#fbf8b5" cx="755.33" cy="58.2" rx="6.31" ry="3.57"/>
          </g>
          <g>
            <ellipse strokeWidth="0px" fill="#ffde3b" cx="797.93" cy="63.43" rx="12.32" ry="7.36"/>
            <ellipse strokeWidth="0px" fill="#fbf8b5" cx="797.93" cy="63.43" rx="6.31" ry="3.57"/>
          </g>
          <g>
            <ellipse strokeWidth="0px" fill="#ffde3b" cx="840.53" cy="68.66" rx="12.32" ry="7.36"/>
            <ellipse strokeWidth="0px" fill="#fbf8b5" cx="840.53" cy="68.67" rx="6.31" ry="3.57"/>
          </g>
          <g>
            <ellipse strokeWidth="0px" fill="#ffde3b" cx="883.13" cy="73.9" rx="12.32" ry="7.36"/>
            <ellipse strokeWidth="0px" fill="#fbf8b5" cx="883.13" cy="73.91" rx="6.31" ry="3.57"/>
          </g>
          <g>
            <ellipse strokeWidth="0px" fill="#ffde3b" cx="925.73" cy="79.14" rx="12.32" ry="7.36"/>
            <ellipse strokeWidth="0px" fill="#fbf8b5" cx="925.73" cy="79.14" rx="6.31" ry="3.57"/>
          </g>
        </g>
        <g>
          <g>
            <ellipse strokeWidth="0px" fill="#ffde3b" cx="927.38" cy="236.65" rx="12.32" ry="7.36"/>
            <ellipse strokeWidth="0px" fill="#fbf8b5" cx="927.38" cy="236.66" rx="6.31" ry="3.57"/>
          </g>
          <g>
            <ellipse strokeWidth="0px" fill="#ffde3b" cx="884.82" cy="242.62" rx="12.32" ry="7.36"/>
            <ellipse strokeWidth="0px" fill="#fbf8b5" cx="884.82" cy="242.63" rx="6.31" ry="3.57"/>
          </g>
          <g>
            <ellipse strokeWidth="0px" fill="#ffde3b" cx="842.25" cy="248.59" rx="12.32" ry="7.36"/>
            <ellipse strokeWidth="0px" fill="#fbf8b5" cx="842.25" cy="248.6" rx="6.31" ry="3.57"/>
          </g>
          <g>
            <ellipse strokeWidth="0px" fill="#ffde3b" cx="799.69" cy="254.56" rx="12.32" ry="7.36"/>
            <ellipse strokeWidth="0px" fill="#fbf8b5" cx="799.69" cy="254.56" rx="6.31" ry="3.57"/>
          </g>
          <g>
            <ellipse strokeWidth="0px" fill="#ffde3b" cx="757.12" cy="260.53" rx="12.32" ry="7.36"/>
            <ellipse strokeWidth="0px" fill="#fbf8b5" cx="757.12" cy="260.53" rx="6.31" ry="3.57"/>
          </g>
          <g>
            <ellipse strokeWidth="0px" fill="#ffde3b" cx="714.56" cy="266.5" rx="12.32" ry="7.36"/>
            <ellipse strokeWidth="0px" fill="#fbf8b5" cx="714.56" cy="266.5" rx="6.31" ry="3.57"/>
          </g>
          <g>
            <ellipse strokeWidth="0px" fill="#ffde3b" cx="671.99" cy="272.47" rx="12.32" ry="7.36"/>
            <ellipse strokeWidth="0px" fill="#fbf8b5" cx="671.99" cy="272.47" rx="6.31" ry="3.57"/>
          </g>
          <g>
            <ellipse strokeWidth="0px" fill="#ffde3b" cx="629.43" cy="278.43" rx="12.32" ry="7.36"/>
            <ellipse strokeWidth="0px" fill="#fbf8b5" cx="629.43" cy="278.44" rx="6.31" ry="3.57"/>
          </g>
          <g>
            <ellipse strokeWidth="0px" fill="#ffde3b" cx="586.86" cy="284.4" rx="12.32" ry="7.36"/>
            <ellipse strokeWidth="0px" fill="#fbf8b5" cx="586.86" cy="284.41" rx="6.31" ry="3.57"/>
          </g>
          <g>
            <ellipse strokeWidth="0px" fill="#ffde3b" cx="544.3" cy="290.37" rx="12.32" ry="7.36"/>
            <ellipse strokeWidth="0px" fill="#fbf8b5" cx="544.3" cy="290.38" rx="6.31" ry="3.57"/>
          </g>
        </g>
        <g>
          <g>
            <ellipse strokeWidth="0px" fill="#ffde3b" cx="74.1" cy="236.65" rx="12.32" ry="7.36"/>
            <ellipse strokeWidth="0px" fill="#fbf8b5" cx="74.1" cy="236.66" rx="6.31" ry="3.57"/>
          </g>
          <g>
            <ellipse strokeWidth="0px" fill="#ffde3b" cx="116.66" cy="242.62" rx="12.32" ry="7.36"/>
            <ellipse strokeWidth="0px" fill="#fbf8b5" cx="116.66" cy="242.63" rx="6.31" ry="3.57"/>
          </g>
          <g>
            <ellipse strokeWidth="0px" fill="#ffde3b" cx="159.23" cy="248.59" rx="12.32" ry="7.36"/>
            <ellipse strokeWidth="0px" fill="#fbf8b5" cx="159.23" cy="248.6" rx="6.31" ry="3.57"/>
          </g>
          <g>
            <ellipse strokeWidth="0px" fill="#ffde3b" cx="201.79" cy="254.56" rx="12.32" ry="7.36"/>
            <ellipse strokeWidth="0px" fill="#fbf8b5" cx="201.79" cy="254.56" rx="6.31" ry="3.57"/>
          </g>
          <g>
            <ellipse strokeWidth="0px" fill="#ffde3b" cx="244.36" cy="260.53" rx="12.32" ry="7.36"/>
            <ellipse strokeWidth="0px" fill="#fbf8b5" cx="244.36" cy="260.53" rx="6.31" ry="3.57"/>
          </g>
          <g>
            <ellipse strokeWidth="0px" fill="#ffde3b" cx="286.92" cy="266.5" rx="12.32" ry="7.36"/>
            <ellipse strokeWidth="0px" fill="#fbf8b5" cx="286.92" cy="266.5" rx="6.31" ry="3.57"/>
          </g>
          <g>
            <ellipse strokeWidth="0px" fill="#ffde3b" cx="329.48" cy="272.47" rx="12.32" ry="7.36"/>
            <ellipse strokeWidth="0px" fill="#fbf8b5" cx="329.48" cy="272.47" rx="6.31" ry="3.57"/>
          </g>
          <g>
            <ellipse strokeWidth="0px" fill="#ffde3b" cx="372.05" cy="278.43" rx="12.32" ry="7.36"/>
            <ellipse strokeWidth="0px" fill="#fbf8b5" cx="372.05" cy="278.44" rx="6.31" ry="3.57"/>
          </g>
          <g>
            <ellipse strokeWidth="0px" fill="#ffde3b" cx="414.61" cy="284.4" rx="12.32" ry="7.36"/>
            <ellipse strokeWidth="0px" fill="#fbf8b5" cx="414.61" cy="284.41" rx="6.31" ry="3.57"/>
          </g>
          <g>
            <ellipse strokeWidth="0px" fill="#ffde3b" cx="457.18" cy="290.37" rx="12.32" ry="7.36"/>
            <ellipse strokeWidth="0px" fill="#fbf8b5" cx="457.18" cy="290.38" rx="6.31" ry="3.57"/>
          </g>
        </g>
        <g>
          <g>
            <ellipse strokeWidth="0px" fill="#ffde3b" cx="25.2" cy="110.46" rx="12.32" ry="7.36"/>
            <ellipse strokeWidth="0px" fill="#fbf8b5" cx="25.2" cy="110.47" rx="6.31" ry="3.57"/>
          </g>
          <g>
            <ellipse strokeWidth="0px" fill="#ffde3b" cx="25.2" cy="134.44" rx="12.32" ry="7.36"/>
            <ellipse strokeWidth="0px" fill="#fbf8b5" cx="25.2" cy="134.44" rx="6.31" ry="3.57"/>
          </g>
          <g>
            <ellipse strokeWidth="0px" fill="#ffde3b" cx="25.2" cy="158.41" rx="12.32" ry="7.36"/>
            <ellipse strokeWidth="0px" fill="#fbf8b5" cx="25.2" cy="158.42" rx="6.31" ry="3.57"/>
          </g>
          <g>
            <ellipse strokeWidth="0px" fill="#ffde3b" cx="25.2" cy="182.39" rx="12.32" ry="7.36"/>
            <ellipse strokeWidth="0px" fill="#fbf8b5" cx="25.2" cy="182.39" rx="6.31" ry="3.57"/>
          </g>
          <g>
            <ellipse strokeWidth="0px" fill="#ffde3b" cx="25.2" cy="206.36" rx="12.32" ry="7.36"/>
            <ellipse strokeWidth="0px" fill="#fbf8b5" cx="25.2" cy="206.37" rx="6.31" ry="3.57"/>
          </g>
        </g>
        <g>
          <g>
            <ellipse strokeWidth="0px" fill="#ffde3b" cx="974.84" cy="110.46" rx="12.32" ry="7.36"/>
            <ellipse strokeWidth="0px" fill="#fbf8b5" cx="974.84" cy="110.47" rx="6.31" ry="3.57"/>
          </g>
          <g>
            <ellipse strokeWidth="0px" fill="#ffde3b" cx="974.84" cy="134.44" rx="12.32" ry="7.36"/>
            <ellipse strokeWidth="0px" fill="#fbf8b5" cx="974.84" cy="134.44" rx="6.31" ry="3.57"/>
          </g>
          <g>
            <ellipse strokeWidth="0px" fill="#ffde3b" cx="974.84" cy="158.41" rx="12.32" ry="7.36"/>
            <ellipse strokeWidth="0px" fill="#fbf8b5" cx="974.84" cy="158.42" rx="6.31" ry="3.57"/>
          </g>
          <g>
            <ellipse strokeWidth="0px" fill="#ffde3b" cx="974.84" cy="182.39" rx="12.32" ry="7.36"/>
            <ellipse strokeWidth="0px" fill="#fbf8b5" cx="974.84" cy="182.39" rx="6.31" ry="3.57"/>
          </g>
          <g>
            <ellipse strokeWidth="0px" fill="#ffde3b" cx="974.84" cy="206.36" rx="12.32" ry="7.36"/>
            <ellipse strokeWidth="0px" fill="#fbf8b5" cx="974.84" cy="206.37" rx="6.31" ry="3.57"/>
          </g>
        </g>
        <g>
          <ellipse strokeWidth="0px" fill="#ffde3b" cx="39.21" cy="87.53" rx="12.32" ry="7.36"/>
          <ellipse strokeWidth="0px" fill="#fbf8b5" cx="39.21" cy="87.54" rx="6.31" ry="3.57"/>
        </g>
        <g>
          <ellipse strokeWidth="0px" fill="#ffde3b" cx="39.96" cy="228.51" rx="12.32" ry="7.36"/>
          <ellipse strokeWidth="0px" fill="#fbf8b5" cx="39.96" cy="228.51" rx="6.31" ry="3.57"/>
        </g>
        <g>
          <ellipse strokeWidth="0px" fill="#ffde3b" cx="961.18" cy="87.53" rx="12.32" ry="7.36"/>
          <ellipse strokeWidth="0px" fill="#fbf8b5" cx="961.18" cy="87.54" rx="6.31" ry="3.57"/>
        </g>
        <g>
          <ellipse strokeWidth="0px" fill="#ffde3b" cx="961.93" cy="228.51" rx="12.32" ry="7.36"/>
          <ellipse strokeWidth="0px" fill="#fbf8b5" cx="961.93" cy="228.51" rx="6.31" ry="3.57"/>
        </g>
      </g>
      <g>
        <ellipse strokeWidth="0px" fill="#fff" cx="755.75" cy="58.24" rx="12.96" ry="7.74"/>
        <path strokeWidth="0px" fill="url(#radial-gradient-2)" d="m781.39,58.24c-20.01,3.11-22.53,5.63-25.63,25.63-3.11-20.01-5.63-22.53-25.63-25.63,20.01-3.11,22.53-5.63,25.63-25.63,3.11,20.01,5.63,22.53,25.63,25.63Z"/>
        <path strokeWidth="0px" fill="url(#radial-gradient-3)" d="m771.93,74.42c-14.85-11.57-17.51-11.57-32.36,0,11.57-14.85,11.57-17.51,0-32.36,14.85,11.57,17.51,11.57,32.36,0-11.57,14.85-11.57,17.51,0,32.36Z"/>
      </g>
      <g>
        <ellipse strokeWidth="0px" fill="#fff" cx="798.43" cy="63.32" rx="12.96" ry="7.74"/>
        <path strokeWidth="0px" fill="url(#radial-gradient-4)" d="m824.06,63.32c-20.01,3.11-22.53,5.63-25.63,25.63-3.11-20.01-5.63-22.53-25.63-25.63,20.01-3.11,22.53-5.63,25.63-25.63,3.11,20.01,5.63,22.53,25.63,25.63Z"/>
        <path strokeWidth="0px" fill="url(#radial-gradient-5)" d="m814.61,79.5c-14.85-11.57-17.51-11.57-32.36,0,11.57-14.85,11.57-17.51,0-32.36,14.85,11.57,17.51,11.57,32.36,0-11.57,14.85-11.57,17.51,0,32.36Z"/>
      </g>
      <g>
        <ellipse strokeWidth="0px" fill="#fff" cx="840.82" cy="69.12" rx="12.96" ry="7.74"/>
        <path strokeWidth="0px" fill="url(#radial-gradient-6)" d="m866.45,69.12c-20.01,3.11-22.53,5.63-25.63,25.63-3.11-20.01-5.63-22.53-25.63-25.63,20.01-3.11,22.53-5.63,25.63-25.63,3.11,20.01,5.63,22.53,25.63,25.63Z"/>
        <path strokeWidth="0px" fill="url(#radial-gradient-7)" d="m857,85.3c-14.85-11.57-17.51-11.57-32.36,0,11.57-14.85,11.57-17.51,0-32.36,14.85,11.57,17.51,11.57,32.36,0-11.57,14.85-11.57,17.51,0,32.36Z"/>
      </g>
      <g>
        <ellipse strokeWidth="0px" fill="#fff" cx="159.34" cy="248.75" rx="12.96" ry="7.74"/>
        <path strokeWidth="0px" fill="url(#radial-gradient-8)" d="m184.97,248.75c-20.01,3.11-22.53,5.63-25.63,25.63-3.11-20.01-5.63-22.53-25.63-25.63,20.01-3.11,22.53-5.63,25.63-25.63,3.11,20.01,5.63,22.53,25.63,25.63Z"/>
        <path strokeWidth="0px" fill="url(#radial-gradient-9)" d="m175.52,264.93c-14.85-11.57-17.51-11.57-32.36,0,11.57-14.85,11.57-17.51,0-32.36,14.85,11.57,17.51,11.57,32.36,0-11.57,14.85-11.57,17.51,0,32.36Z"/>
      </g>
      <g>
        <ellipse strokeWidth="0px" fill="#fff" cx="202.35" cy="254.54" rx="12.96" ry="7.74"/>
        <path strokeWidth="0px" fill="url(#radial-gradient-10)" d="m227.98,254.54c-20.01,3.11-22.53,5.63-25.63,25.63-3.11-20.01-5.63-22.53-25.63-25.63,20.01-3.11,22.53-5.63,25.63-25.63,3.11,20.01,5.63,22.53,25.63,25.63Z"/>
        <path strokeWidth="0px" fill="url(#radial-gradient-11)" d="m218.53,270.72c-14.85-11.57-17.51-11.57-32.36,0,11.57-14.85,11.57-17.51,0-32.36,14.85,11.57,17.51,11.57,32.36,0-11.57,14.85-11.57,17.51,0,32.36Z"/>
      </g>
      <g>
        <ellipse strokeWidth="0px" fill="#fff" cx="244.43" cy="260.68" rx="12.96" ry="7.74"/>
        <path strokeWidth="0px" fill="url(#radial-gradient-12)" d="m270.06,260.68c-20.01,3.11-22.53,5.63-25.63,25.63-3.11-20.01-5.63-22.53-25.63-25.63,20.01-3.11,22.53-5.63,25.63-25.63,3.11,20.01,5.63,22.53,25.63,25.63Z"/>
        <path strokeWidth="0px" fill="url(#radial-gradient-13)" d="m260.61,276.86c-14.85-11.57-17.51-11.57-32.36,0,11.57-14.85,11.57-17.51,0-32.36,14.85,11.57,17.51,11.57,32.36,0-11.57,14.85-11.57,17.51,0,32.36Z"/>
      </g>
      <g>
        <ellipse strokeWidth="0px" fill="#fff" cx="842.19" cy="248.53" rx="12.96" ry="7.74"/>
        <path strokeWidth="0px" fill="url(#radial-gradient-14)" d="m867.83,248.53c-20.01,3.11-22.53,5.63-25.63,25.63-3.11-20.01-5.63-22.53-25.63-25.63,20.01-3.11,22.53-5.63,25.63-25.63,3.11,20.01,5.63,22.53,25.63,25.63Z"/>
        <path strokeWidth="0px" fill="url(#radial-gradient-15)" d="m858.37,264.71c-14.85-11.57-17.51-11.57-32.36,0,11.57-14.85,11.57-17.51,0-32.36,14.85,11.57,17.51,11.57,32.36,0-11.57,14.85-11.57,17.51,0,32.36Z"/>
      </g>
      <g>
        <ellipse strokeWidth="0px" fill="#fff" cx="799.16" cy="254.66" rx="12.96" ry="7.74"/>
        <path strokeWidth="0px" fill="url(#radial-gradient-16)" d="m824.79,254.66c-20.01,3.11-22.53,5.63-25.63,25.63-3.11-20.01-5.63-22.53-25.63-25.63,20.01-3.11,22.53-5.63,25.63-25.63,3.11,20.01,5.63,22.53,25.63,25.63Z"/>
        <path strokeWidth="0px" fill="url(#radial-gradient-17)" d="m815.34,270.84c-14.85-11.57-17.51-11.57-32.36,0,11.57-14.85,11.57-17.51,0-32.36,14.85,11.57,17.51,11.57,32.36,0-11.57,14.85-11.57,17.51,0,32.36Z"/>
      </g>
      <g>
        <ellipse strokeWidth="0px" fill="#fff" cx="757.39" cy="260.84" rx="12.96" ry="7.74"/>
        <path strokeWidth="0px" fill="url(#radial-gradient-18)" d="m783.02,260.84c-20.01,3.11-22.53,5.63-25.63,25.63-3.11-20.01-5.63-22.53-25.63-25.63,20.01-3.11,22.53-5.63,25.63-25.63,3.11,20.01,5.63,22.53,25.63,25.63Z"/>
        <path strokeWidth="0px" fill="url(#radial-gradient-19)" d="m773.57,277.02c-14.85-11.57-17.51-11.57-32.36,0,11.57-14.85,11.57-17.51,0-32.36,14.85,11.57,17.51,11.57,32.36,0-11.57,14.85-11.57,17.51,0,32.36Z"/>
      </g>
      <g>
        <ellipse strokeWidth="0px" fill="#fff" cx="245.34" cy="58.15" rx="12.96" ry="7.74"/>
        <path strokeWidth="0px" fill="url(#radial-gradient-20)" d="m270.98,58.15c-20.01,3.11-22.53,5.63-25.63,25.63-3.11-20.01-5.63-22.53-25.63-25.63,20.01-3.11,22.53-5.63,25.63-25.63,3.11,20.01,5.63,22.53,25.63,25.63Z"/>
        <path strokeWidth="0px" fill="url(#radial-gradient-21)" d="m261.52,74.33c-14.85-11.57-17.51-11.57-32.36,0,11.57-14.85,11.57-17.51,0-32.36,14.85,11.57,17.51,11.57,32.36,0-11.57,14.85-11.57,17.51,0,32.36Z"/>
      </g>
      <g>
        <ellipse strokeWidth="0px" fill="#fff" cx="202.8" cy="63.36" rx="12.96" ry="7.74"/>
        <path strokeWidth="0px" fill="url(#radial-gradient-22)" d="m228.44,63.36c-20.01,3.11-22.53,5.63-25.63,25.63-3.11-20.01-5.63-22.53-25.63-25.63,20.01-3.11,22.53-5.63,25.63-25.63,3.11,20.01,5.63,22.53,25.63,25.63Z"/>
        <path strokeWidth="0px" fill="url(#radial-gradient-23)" d="m218.98,79.54c-14.85-11.57-17.51-11.57-32.36,0,11.57-14.85,11.57-17.51,0-32.36,14.85,11.57,17.51,11.57,32.36,0-11.57,14.85-11.57,17.51,0,32.36Z"/>
      </g>
      <g>
        <ellipse strokeWidth="0px" fill="#fff" cx="161.25" cy="68.53" rx="12.96" ry="7.74"/>
        <path strokeWidth="0px" fill="url(#radial-gradient-24)" d="m186.88,68.53c-20.01,3.11-22.53,5.63-25.63,25.63-3.11-20.01-5.63-22.53-25.63-25.63,20.01-3.11,22.53-5.63,25.63-25.63,3.11,20.01,5.63,22.53,25.63,25.63Z"/>
        <path strokeWidth="0px" fill="url(#radial-gradient-25)" d="m177.43,84.71c-14.85-11.57-17.51-11.57-32.36,0,11.57-14.85,11.57-17.51,0-32.36,14.85,11.57,17.51,11.57,32.36,0-11.57,14.85-11.57,17.51,0,32.36Z"/>
      </g>
    </svg>
  )
}