import { redirect } from "react-router-dom"
// @ts-expect-error
import gameCardBg from "../../assets/gameCard/gameCardBg.png"

export const GameCard = ({
  title,
  gameImg,
  url
}: {
  title: string,
  gameImg: string,
  url: string
}) => {

  return (
    <a href={url} style={{ textDecoration: "none" }}>
      <div style={{
        width: "375px",
        height: "223px",
        display: "flex",
        flexDirection: "column",
        gap: "0px",
        padding: "0px",
        margin: "0px",
        cursor: "pointer"
      }}>
        <div style={{
          width: "375px",
          height: "156px",
          backgroundImage: `url(${gameCardBg})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          display: "flex",
          justifyContent: "center",
          alignItems: "center"
        }}>
          <img src={require(`../../assets/gameCard/gameCard_${gameImg}.png`)}
            alt="gameTitleImg"
            style={{ maxWidth: "310px", height: "auto" }} />
        </div>
        <div style={{
          fontSize: "24px",
          fontWeight: "900px",
          color: "black",
          width: "355px",
          height: "67px",
          backgroundColor: "white",
          borderBottomLeftRadius: "20px",
          borderBottomRightRadius: "20px",
          display: "flex",
          alignItems: "center",
          paddingLeft: "20px",
        }}>
          {title}
        </div>
      </div>
    </a>

  )
}
