import { useState, useEffect } from "react"
import { BarDiceLogo } from "../assets/BarDiceLogo"
import { PhoneEntry } from "./components/PhoneEntry"
import { CodeEntry } from "./components/CodeEntry"


import JDDTitle from "./assets/JDDTitle.png"
import JDDCertITech from "./assets/JDDCert_iTech.png"
import JDDCertPIX from "./assets/JDDCert_pix.png"

import { PlayButton } from "./components/PlayButton"
import { SecurityText } from "./components/SecurityText"

import { i18nEn, i18nPt } from "./assets/i18n";
import { GoogleLogin } from '@react-oauth/google'

import { fetcher } from "../../../utils/fetcher"
import { useLocation, useNavigate } from "react-router-dom"
import { useAuthUser, useSignIn } from "react-auth-kit"

export const BarDiceLogin = (props) => {
  const navigate = useNavigate()
  const auth = useAuthUser()
  const location = useLocation()

  useEffect(()=>{
    if (auth()?.id || auth()?.userData.id) {
      console.log("login redirect")
      navigate(location.state?.redirect ?? "/", {replace: true, state: {}})
    }
  }, [auth()?.id])

  const [sent, setSent] = useState(false)
  const [phoneNumber, setPhoneNumber] = useState("")
  const [isValid, setIsValid] = useState(false)
  const [cooldown, setCooldown] = useState(0)
  const [isLoading, setIsLoading] = useState(false)
  const signIn = useSignIn()
  
  let lang = undefined;
  // switch (process.env.REACT_APP_LANG) { // REACT_APP_LANG valid values ["pt", "en"]
  switch (props.jogo) {
    case true: // "pt"
      lang = i18nPt;
      break;
    case false: // "en"
      lang = (i18nEn);
      break;
    default:
      lang = (i18nEn);
      break;
  }
  const i18n = props.jogo ? i18nPt: i18nEn;
  

  const handleGoogleSignIn = async (token) => {
    try {
      setSent(true);
      setIsLoading(true);
      setCooldown(10);
      const resp = await fetcher("POST", null, props.backend+"/2fa/google-signin", {
        encryptedToken: token,
        demo: process.env.REACT_APP_DEMO,
        host: window.location.host
      })
      console.log("endpoint GoogleSignIn response:",resp);
      // update game user state (id, firstName, email, etc)
      signIn({
        token: resp.token,
        expiresIn: 1440,
        tokenType: "Bearer",
        authState: resp.state
      })
      // redirect to BarDiceController
      navigate(location?.state?.redirect ?? "/")
    } catch (error) {
      console.error(error)
    } finally {
      setSent(false)
      setIsLoading(false);
    }
  }

  const handleSend = async (e) => {
    if (isValid && !cooldown) {
      try {
        const resp = await fetcher("POST", null, props.backend+"/2fa/send-verification", {
          phoneNumber: "+1"+phoneNumber.replace(/[^0-9]/gi, "")
        })
        console.log("endpoint Phone Sign In (send phone#)", resp);
        setSent(true)
        setCooldown(10)
      } catch (error) {
        console.error({error: error})
      }
    }
  }

  return (
    <div className={props.jogo ? "jogododadoBg" : "bardice100Bg"}>
      <div id="barDice" className="barDiceLogin">
        <div className="titleContainer">
          {props.jogo ?
            <>
              <img src={JDDTitle} alt="Jogo Do Dado"></img>
            </>
            :
            <>
              <div className="barDiceLogo">
                <BarDiceLogo w={"77.903"} h={"76"} v={"0 0 50 50"} />
              </div>
              <div className="barDiceLoginTitle bigName">Bar Dice 100</div>
            </>
          }
        </div>

        <div className="securityContainer">
          <SecurityText 
            title={i18n.securityTextTitle}
            item1={i18n.securityTextItem1}
            item2={i18n.securityTextItem2}
            item3={i18n.securityTextItem3}
            fontSize={props.jogo ? "small" : "medium"}></SecurityText>
        </div>

        <div className="loginContainer">
          <div className="googleSignInContainer">
            <GoogleLogin
              // theme="outline" // default
              theme="filled_black"
              // theme="filled_blue"
              width="250px"
              type="standard"  // standard/icon
              size="large" // default
              logo_alignment="left" // default
              shape="pill" // pill/rectangle
              text="continue_with"
              onSuccess={credentialResp => {
                console.log("Google API response:", credentialResp);
                let token = credentialResp.credential;
                if (token) {
                  handleGoogleSignIn(token);
                } else {
                  console.error("Malformed Google API credential object, please contact remote@radsoftware.io");
                }
              }}
              onError={() =>
                // Note: Closing the popup window doesnt count as onError
                console.error("Google Sign-In failed at this time, please contact remote@radsoftware.io")}
            ></GoogleLogin>
          </div>
          {!sent ? (
            // <PhoneEntry
            //   title={i18n.phoneEntryTitle}
            //   phoneNumber={phoneNumber}
            //   setPhoneNumber={setPhoneNumber}
            //   isValid={isValid}
            //   setIsValid={setIsValid}
            //   handleSend={handleSend}
            //   placeholder={i18n.phoneEntryPlaceholder}/>
            null
          ) : (
            // <CodeEntry
            //   title={i18n.codeEntryTitle}
            //   greyText={i18n.codeEntryGreyText}
            //   phoneNumber={phoneNumber}
            //   isLoading={isLoading}
            //   setIsLoading={setIsLoading}
            //   setSent={setSent}
            //   backend={props.backend}
            //   demo={props.demo}/>
            null
          )}
          {/* <PlayButton
            id="loginSender"
            onClick={handleSend}
            className={!sent ? "submit" : "resend"}
            label={!sent ? i18n.playButtonLabel : `${i18n.playButtonLabelAlt} ${cooldown}s`}>
          </PlayButton> */}
        </div>

        <div className="certsContainer">
          {props.jogo  && !sent ?
            <>
                <img src={JDDCertPIX} alt="Certificado pelo PIX" height={30} style={{padding:"5px"}}></img>
                <img src={JDDCertITech} alt="Certificado pelo iTech" height={30} style={{padding:"5px"}}></img>
            </>
            :
            <>
              {/* placeholder for bar dice 100 certs */}
              {/* <img src={JDDCertPIX} alt="Certified by PIX" height={30} style={{padding:"5px"}}></img>
              <img src={JDDCertITech} alt="Certified by iTech" height={30} style={{padding:"5px"}}></img> */}
            </>
          }
        </div>
      </div>
    </div>
  )
}