import { useEffect, useRef, useState } from "react";
import { Breadcrumbs } from "../../shared/Breadcrumbs";
import { Header } from "../../shared/Header";
import { ActionsCard } from "./ActionsCard";
import { ContactInfoCard } from "./ContactInfoCard";
import { NotesCard } from "./NotesCard";
import { RecentBetsCard } from "./RecentBetsCard";
import { RecentTransactionsCard } from "./RecentTransactionsCard";
import { UserSummaryCard } from "./UserSummaryCard";
import { useAuthHeader } from "react-auth-kit";
import { fetcher } from "../../../../../../utils/fetcher";


const Style: { [key: string]: React.CSSProperties } = {
    container: {
        // width: "100%",
        maxHeight: "700px",
        padding: "10px",
        display: "flex",
        flexDirection: "column",

        borderRadius: "15px",

        background: "#111C44",
    },

};

type Props = {

}


export function IndividualUser({

}: Props) {
    const authHeader = useAuthHeader();
    const backend = useRef(
        process.env.REACT_APP_NODE_ENV === "production"
            ? process.env.REACT_APP_PROD // TODO: dedicated DB server
            : process.env.REACT_APP_NODE_ENV === "staging"
                ? process.env.REACT_APP_STAGING
                : "http://localhost:4000"
    );

    const [userId, setUserId] = useState<number>(0);
    const [username, setUsername] = useState<string>("");
    const [email, setEmail] = useState<string>("");
    const [updatedAt, setUpdatedAt] = useState<string>("");
    const [role, setRole] = useState<string>("");
    const [firstName, setFirstName] = useState<string>("");
    const [lastName, setLastName] = useState<string>("");
    const [balance, setBalance] = useState<Number>(0);

    const [tableItems, setTableItems] = useState<any[]>([]);
    // let tableItems: Array<{
    //         id: string,
    //         type: string,
    //         amount: string,
    //         date: string
    //     }> = [];
    let arr: { id: string; type: string; amount: string; date: string; }[] = [];

    let transactions: any[] = [];
    useEffect(() => {
        const currentUrl = window.location.href;
        const urlSections = currentUrl.split("/");
        const userId = urlSections[urlSections.length - 1];

        (async () => {
            await fetcher(
                "GET", authHeader(),
                backend.current + `/users/${userId}`
            ).then(data => {
                console.log("data", data);
                setUserId(data.id);
                setUsername(data.username);
                setEmail(data.email);
                setUpdatedAt(data.updatedAt);
                setRole(data.role);
                setFirstName(data.firstName);
                setLastName(data.lastName);
                setBalance(data.balance);
            }).catch(e => {
                console.error(e);
            });
        })();

        
        (async () => {
            await fetcher(
                "GET", authHeader(),
                backend.current + `/transactions/user/${userId}`
            ).then(data => {
                console.log("data", data);
                // for (let i = 0; i < data.length; i++) {
                //     const eachTransaction = data[i];
                //     arr.push({
                //         id: eachTransaction.id,
                //         type: eachTransaction.action,
                //         amount: String(Number(eachTransaction.amount) / 100),
                //         date: eachTransaction.updatedAt,
                //     });
                // }
                setTableItems(data.transactions);
                
            }).catch(e => {
                console.error(e);
            });
            
        })();
        
        
    }, []);

    return (
        <div style={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            padding: "10px"
        }}>
            {/* Breadcrumbs */}
            <Breadcrumbs text={"Pages / Users"}></Breadcrumbs>
            {/* Top row */}
            <Header title={`User ID ${userId}`}></Header>
            {/* Body */}
            <section style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
                {/* Row 1 */}
                <div style={{ display: "flex", justifyContent: "space-between", gap: "20px" }}>
                    <UserSummaryCard email={email}
                        balance={String(balance)}
                        updatedAt={updatedAt}>
                    </UserSummaryCard>
                    <RecentTransactionsCard username={username} tableItems={tableItems}></RecentTransactionsCard>
                    <ActionsCard></ActionsCard>
                </div>
                {/* Row 2 */}
                <div style={{ display: "flex", justifyContent: "space-between", gap: "20px" }}>
                    <ContactInfoCard firstName={firstName}
                        lastName={lastName}
                        email={email}
                        updatedAt={updatedAt}>
                    </ContactInfoCard>
                    <RecentBetsCard></RecentBetsCard>
                    <NotesCard username="user_123"></NotesCard>
                </div>

            </section>
        </div>
    );
};











