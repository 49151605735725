import { UsersTable } from "./UsersTable"
import { Breadcrumbs } from "../shared/Breadcrumbs"
import { Header } from "../shared/Header"
import { useAuthUser, useAuthHeader } from "react-auth-kit";
import { useEffect, useRef, useState } from "react";
import { fetcher } from "../../../../../utils/fetcher";
import { moneyFormatter } from "../../../../../utils/moneyFormatter";

type UserObject = {
  balance: number,
  createdAt: string,
  details: any,   // eg {Bar Dice 100: 85}
  email: string,
  firstName: string,
  gameHistory: any,
  id: number,
  lastName: null | string,
  locations: Array<any>,
  password: null | string,
  phoneNumber: string,
  role: string,
  settings: any,
  updatedAt: string,
  username: string,
  transactions: Array<any>
}
type TableDataRow = {
  userid: string,
  email: string,
  balance: string,
  role: string,
  deposits: string,
  withdrawals: string,
  bets: string,
  profits: string,
  winrate: string,
};
export const Users = ({
  gameUrlRef,
}: {
  gameUrlRef: React.MutableRefObject<string>,
}) => {
  const auth = useAuthUser();
  const authHeader = useAuthHeader();

  const backend = useRef(
    process.env.REACT_APP_NODE_ENV === "production"
      ? process.env.REACT_APP_PROD // TODO: dedicated DB server
      : process.env.REACT_APP_NODE_ENV === "staging"
        ? process.env.REACT_APP_STAGING
        : "http://localhost:4000"
  );

  // let tableData: Array<TableDataRow> = [];
  const [tableData, setTableData] = useState<Array<TableDataRow>>([]);

  let temp: TableDataRow[] = [];
  useEffect(() => {
    if (auth()?.id) {
      (async () => {
        let usersRawData: Array<any>;
        // get raw data for all users
        await fetcher(
          "POST",
          authHeader(),
          backend.current + `/superadminpanel/getallusers`,
          {
            game: gameUrlRef.current,
          }
        ).then(data => {
          // data => { message: string, success: boolean, users: Array}
          console.log("data", data)
          // console.log("data", data);
          usersRawData = data.users;
          // for each user, get all transaction data (most recent 50) 
          // "Select * 
          // From Transactions 
          // Where userId = {paramId}
          // Limit 50 
          // Order by id Desc"
          // for (let index = 0; index < data.length; index++) {
          //   const element = usersRawData[index];
          //   await fetcher(
          //     "GET", authHeader(),
          //     backend.current + `/superadminpanel/getallusers`//?&limit=50&order=updatedAt-DESC`
          //     // backend.current + `/transactions/user/${element.id}`//?&limit=50&order=updatedAt-DESC`
          //   ).then(data => {
          //     // console.log("user id " + element.id, data.transactions);
          //     // data.transactions: Array<{ action, actionType, adminId, amount, createdAt, details, gameId, gameName, id, locationId, status, updatedAt, userId }>
          //     // element.transactions = data.transactions; 
          //     console.log(data);
          //   }).catch(e => {
          //     console.error(e);
          //   });
          // }
          // Format raw data (total deposits, total withdrawals, balance raw number in pennies to CURR$##.##) to be more readible to admin
          const gameUrl = gameUrlRef.current;
          for (let index = 0; index < usersRawData.length; index++) {
            const each: UserObject = usersRawData[index];
            let curr = (gameUrl == "jogo") ? "BR$" : "USD$";

            temp.push({
              userid: String(each.id),
              email: each.email,
              balance: moneyFormatter(each.balance / 100, curr, true),
              role: each.role,
              deposits: String("deposits"),
              withdrawals: String("withdrawal"),
              bets: String(`${curr}${each.details[convertGameUrlSegment(gameUrl)]}`),
              profits: "profits?",
              winrate: "winrate?",
            });
          }
          // usersRawData.map((each: UserObject) => {
          //   // Summing of deposits and withdrawal
          //   let deposit = 0;
          //   let withdrawal = 0;
          //   let totalBet = 0;
          // for (let index = 0; index < each.transactions.length; index++) {
          //     const eachTransaction = each.transactions[index];
          //     if (eachTransaction.action == "deposit") {
          //       deposit = deposit + eachTransaction.amount;
          //     } else if (eachTransaction.action == "withdrawal") {
          //       withdrawal = withdrawal + eachTransaction.amount;
          //     }
          //     // transaction.detail format looks something like:
          //     /*
          //       '{\"bet\":1,\"multiplier\":\"2.00\",\"guess\":\"2.00\",\"profit\":\"1.00\"}'
          //     */
          //     try {
          //       let detailsArr = String(eachTransaction.details).split(",");
          //       let betIndex = detailsArr.map((each: string) => each.includes("bet")).indexOf(true);
          //       let bet = detailsArr[betIndex].substring(detailsArr[betIndex].indexOf(":")+1); // for the above example, "1"
          //       console.log("bet", bet);
          //       totalBet = totalBet + Number(bet);
          //       console.log(detailsArr);
          //     } catch (error) {
          //     }
          //   }     
          //   deposit = moneyFormatter(deposit / 100, curr, true);
          //   withdrawal = moneyFormatter(withdrawal / 100, curr, true);
          // });
          setTableData(temp);
        }).catch(e => {
          console.error(e);
        });


        // await fetcher(
        //   "GET", authHeader(),
        //   backend.current + `/users?&order=updatedAt-DESC`
        // ).then(data => {
        //   console.log(data, data);
        //   data.map((each: UserObject) => {
        //     let curr = each.details["Jogo Do Dado"] ? "BR$" : "USD$"
        //     temp.push({
        //       userid: String(each.id),
        //       email: each.email,
        //       balance: moneyFormatter(each.balance / 100, curr, true),
        //       role: each.role,
        //       deposits: "deposit",
        //       withdrawals: "string",
        //       bets: "bets",
        //       profits: "profits",
        //       winrate: "winrate",
        //     });
        //     setTableData(temp);
        //   });
        // }).catch(e => {
        //   console.error(e);
        // });
      })();
    }
  }, [auth()?.id])

  return (
    <div style={{
      display: "flex",
      flexDirection: "column",
      gap: "20px",
      paddingLeft: "10px",
      paddingRight: "10px",
    }}>
      {/* Breadcrumbs */}
      <Breadcrumbs text={"Pages / Users"}></Breadcrumbs>
      {/* Top row */}
      <Header title="Users"></Header>
      {/* Body */}
      <UsersTable data={tableData}></UsersTable>
    </div>
  )
}

// import { useEffect, useRef, useState } from "react"
// import { fetcher } from "../../../../utils/fetcher"
// import { useAuthHeader, useAuthUser } from "react-auth-kit"
// import { useLocation, useNavigate } from "react-router-dom"

// export const Users = (props) => {
//   const navigate = useNavigate()
//   const location = useLocation()

//   const username = useRef("")
//   const phoneNumber = useRef("")
//   const email = useRef("")
//   const firstName = useRef("")
//   const lastName = useRef("")
//   const role = useRef("")
//   const sortBy = useRef("")
//   const order = useRef("")
//   const offset = useRef(0)
//   const signupStart = useRef("")
//   const signupEnd = useRef("")

//   const [search, setSearch] = useState([])
//   const [more, setMore] = useState(true)

//   const handleSubmit = async () => {
//     const queryParams = {
//       username: username.current.value,
//       phoneNumber: phoneNumber.current.value,
//       email: email.current.value,
//       firstName: firstName.current.value,
//       lastName: lastName.current.value,
//       role: role.current.value,
//     }

//     let queryString = `?attributes=id_username_phoneNumber_email_firstName_lastName_role_locations_balance_details&limit=50&offset=${offset.current}&order=${sortBy.current?.value}-${order.current?.value}`

//     for (let [k,v] of Object.entries(queryParams)) {
//       if (v) {queryString = queryString+`&${k}=${v}`}
//     }
//     navigate("/super/users"+queryString, {
//       state: {lastQuery: queryString}
//     })
//   }

//   const handleSearch = async () => {
//     await fetcher("GET", authHeader(),
//       props.backend.current+`/users${window.location.search}`,
//     ).then(data => {
//       console.log(data)
//       setSearch(data)
//     })
//   }

//   const getMore = () => {
//     if (more) {offset.current = offset.current+50}
//     console.log("got more")
//   }

//   useEffect(()=>{
//     // TODO: check cached DB
//     if (window.location.href.includes("?")
//       && ((window.location.search !== location.state?.lastQuery ?? '') || search.length === 0)
//     ) {
//       console.log("run search")
//       handleSearch()
//     }
//   }, [window.location.href])

//   return (
//     <div>
//       <div className="row">
//         <input ref={signupStart} type="date"/>
//         <input ref={signupEnd} type="date"/>
//         <select ref={role}>
//           <option value={""}>N/A</option>
//           <option value={"player"}>Player</option>
//           <option value={"admin"}>Admin</option>
//         </select>
//         <select ref={order}>
//           <option value={"DESC"}>Descending</option>
//           <option value={"ASC"}>Ascending</option>
//         </select>
//         <select ref={sortBy}>
//           <option value={"id"}>Id</option>
//           <option value={"updatedAt"}>Last Updated</option>
//           <option value={"balance"}>Balance</option>
//         </select>
//       </div>
//       <div className="row">
//         <input ref={username} type="text"/>
//         <input ref={phoneNumber} type="tel"/>
//         <input ref={email} type="email"/>
//         <input ref={firstName} type="text"/>
//         <input ref={lastName} type="text"/>
//       </div>
//       <div className="row">
//         <div>Results: {search.length}</div>
//         <div disabled={more} onClick={()=>{getMore()}}>More</div>
//         <div className="submit" onClick={()=>{handleSubmit()}}>
//           Search
//         </div>
//       </div>
//       <div className="SA-table">
//         <div className="SA-tableHeader"></div>
//         <div className="SA-tableBody">
//           {search.map((r,i)=>(
//             <div className="SA-tableRow" key={`qr${i}`}>
//               <div>{r.id}</div>
//               <div>{r.username}</div>
//               <div>{r.phoneNumber}</div>
//               <div>{r.email}</div>
//               <div>{`${r?.firstName ?? ""} ${r?.lastName ?? ""}`}</div>
//               <div>{r.balance}</div>
//             </div>
//           ))}
//         </div>
//       </div>
//     </div>
//   )
// }

function convertGameUrlSegment(gameUrlSegment: string) {
  // game variable will be one of:
  // "bardice100", "jogo", "mecca100", "potodice100",
  // "keno414", "javajackpot", "qdice100", "misDados"
  const validGameUrls = ["bardice100", "jogo", "mecca100", "potodice100",
      "keno414", "javajackpot", "qdice100", "misDados"];
  let gameKey = "";
  if (validGameUrls.includes(gameUrlSegment)) {
      // passed gameURL is valid
      // get the key for the game
      switch (gameUrlSegment) {
          case 'bardice100':
              gameKey = 'Bar Dice 100';
              break;
          case 'jogo':
              gameKey = 'Jogo Do Dado';
              break;
          case 'mecca100':
              gameKey = 'Mecca 100';
              break;
          case 'potodice100':
              gameKey = 'Poto Dice 100'
              break;
          case 'keno414':
              gameKey = 'Keno 414'
              break;
          case 'javajackpot':
              gameKey = 'Java Jackpot'
              break;
          case 'qdice100':
              gameKey = 'Q-Dice 100'
              break;
          case 'misDados':
              gameKey = 'Mis Dados'
              break;
          default:
              break;
      }
  }
  return gameKey;
}