import { moneyFormatter } from "../../../../../../utils/moneyFormatter";


const Style: { [key: string]: React.CSSProperties } = {
    container: {
        width: "552px",
        height: "400px",

        paddingTop: "20px",
        paddingBottom: "20px",
        paddingLeft: "20px",
        paddingRight: "20px",

        display: "flex",
        flexDirection: "column",

        borderRadius: "15px",

        background: "#111C44",
    },
    contentsRow: {
        display: "flex",
        // border: "red solid 2px",
        justifyContent: "space-around",
        alignItems: "center",
        // paddingLeft: "15px",
        // paddingRight: "15px",
    },
    rowItemGroup: {
        // padding: "0px"
    },
    bigPrint: {
        fontSize: "18px",
        textAlign: "center",
        color: "#FFFFFF",
        fontWeight: "700",
        margin: "0px"
    },
    tinyPrint: {
        fontSize: "12px",
        textAlign: "center",
        color: "#A3AED0",
        margin: "0px"
    },
};

type Props = {
    email: string,
    balance: string,
    updatedAt: string,
}


export function UserSummaryCard({
    email,
    balance,
    updatedAt
}: Props) {



    return (
        <div style={Style.container}>
            <img src={require("../../../assets/userSummaryCard_Banner.png")}
                alt="Banner image"
                style={{
                    width: "100%",
                    height: "131px",
                    alignSelf: "center",
                    marginBottom: "5px"
                }} />
            <div style={{
                display: "flex", flexDirection: "column", flexGrow: "1",
                // border: "white 2px solid",
                gap: "10px",
                justifyContent: "space-around"
            }}>
                {/* Username header */}
                <h2 style={{ textAlign: "center", margin: "0px", fontSize: "20px" }}>{email}</h2>
                {/* Row 1 */}
                <div style={Style.contentsRow}>
                    <div style={Style.rowItemGroup}>
                        <p style={Style.bigPrint}>{moneyFormatter(Number(balance) / 100, "USD$", true)}</p>
                        <p style={Style.tinyPrint}>Account Balance</p>
                    </div>
                    <div style={Style.rowItemGroup}>
                        <p style={Style.bigPrint}>$??.??</p>
                        <p style={Style.tinyPrint}>Total Deposits</p>
                    </div>
                    <div style={Style.rowItemGroup}>
                        <p style={Style.bigPrint}>$??.??</p>
                        <p style={Style.tinyPrint}>Total Withdrawals</p>
                    </div>
                    <div style={Style.rowItemGroup}>
                        <p style={Style.bigPrint}>
                            <span style={{ color: "lime" }}>???</span>
                            /
                            <span style={{ color: "red" }}>???</span>
                        </p>
                        <p style={Style.tinyPrint}>Bets Won/Lost</p>
                    </div>
                </div>
                {/* Row 2 */}
                <div style={Style.contentsRow}>
                    <div style={Style.rowItemGroup}>
                        <p style={Style.bigPrint}>$??.??</p>
                        <p style={Style.tinyPrint}>GGR</p>
                    </div>
                    <div style={Style.rowItemGroup}>
                        <p style={Style.bigPrint}>$??.??</p>
                        <p style={Style.tinyPrint}>Average Bet Size</p>
                    </div>
                    <div style={Style.rowItemGroup}>
                        <p style={Style.bigPrint}>
                            <span style={{ color: "lime" }}>$???</span>
                            /
                            <span style={{ color: "red" }}>$???</span>
                        </p>
                        <p style={Style.tinyPrint}>Profit/Lost</p>
                    </div>
                </div>
                {/* Row 3 */}
                <div style={Style.contentsRow}>
                    <div style={Style.rowItemGroup}>
                        <p style={Style.bigPrint}>{ (new Date(updatedAt)).toUTCString() }</p>
                        <p style={Style.tinyPrint}>Last Seen</p>
                    </div>
                </div>
            </div>
        </div>
    );
};











