export const NoFlame16 = (props) => {
  return (
    <svg width={props?.w ?? "50"} height={props?.h ?? "50"} viewBox={props?.v ?? "250 450 350 450"} strokeWidth={0}>
      <g id="Layer_2" data-name="Layer 2">
        <polygon points="287.83 675.09 314.43 643.36 489.09 592.23 548.64 768.72 376.67 833.26 339.16 830.49 287.83 675.09"/>
      </g>
      <g id="Layer_1" data-name="Layer 1">
        <path fill="#e8c305" d="m489.87,590.08l-177.86,51.06c-.29.08-.55.26-.73.5l-24.21,31.97c-.28.37-.36.86-.21,1.3l51.92,155.76c.18.53.64.9,1.2.96l36.22,3.58c.21.02.43,0,.63-.08l174.38-65.09c.71-.27,1.09-1.05.84-1.77l-60.45-177.28c-.24-.71-1-1.11-1.72-.9Zm-151.35,236.2l-49.29-147.76c-.15-.45-.06-.95.23-1.32l21.31-26.95c.69-.88,2.08-.63,2.44.43l59.1,176.37c.32.94-.42,1.91-1.41,1.85l-31.12-1.66c-.58-.03-1.08-.41-1.26-.96Zm-18.42-181.54l166.64-47.98c.72-.21,1.48.19,1.72.9l56.9,166.98c.25.72-.13,1.51-.85,1.78l-163.18,60.26c-.74.28-1.57-.12-1.82-.87l-60.37-179.27c-.26-.76.17-1.58.95-1.8Z"/>
        <ellipse fill="#e8c305" cx="435.89" cy="707.78" rx="18.5" ry="19.85" transform="translate(-208.52 182.72) rotate(-19.2)"/>
        <ellipse fill="#e8c305" cx="306.69" cy="709.87" rx="3.45" ry="17.74" transform="translate(-188.75 116.14) rotate(-16.48)"/>
        <ellipse fill="#e8c305" cx="349.24" cy="785.97" rx="3.84" ry="19.59" transform="translate(-239.06 158.58) rotate(-19.2)"/>
      </g>
    </svg>
  )
}