export const i18nEn = {
    winIfThree100s: "Win if there are back to back rolls of 100",
    currency: "$",
    quickBets: [1, 5, 10, 20],
    entries: "Entries",
    // Controller
    bet: "Bet",
    diceGuess: "Dice Guess",
    potentialWin: "Potential Win",
    winIfRollOverGuess: "If the dice rolls over your guess, You win!",
    inNextRoll: "YOU ARE IN THE NEXT ROUND",
    waitNextRoll: "WAIT FOR THE NEXT DICE ROLL",
    placeBet: "PLACE BET",
    nextRollIn: "Next roll in",
    guess: "Guess",
    youWon: "YOU WON",
    rolled: "Rolled",
    payout: "Payout",
    insufficientFundsWarning: "Player has insufficient funds for this bet!",
    // Jackpot Modal 
    jackpotRulesTitle: "Payout",
    jackpotRules1: "The jackpot is triggered after back-to-back rolls of 100.",
    jackpotRules2: "50% will go to one randomly selected ticket holder.",
    jackpotRules3: "25% of the pot is shared equally among active players during the winning round.",
    jackpotRules4: "25% is divided among ticket holders proportionally.",
    jackpotTerms: "Terms",
    jackpotKeepAnEye: "Keep an eye on your ticket count displayed alongside the jackpot value to estimate your potential win.",
    returnHome: "CLOSE",
    // Nav
    balanceDeposit: "Deposit +",
    // Toasts
    lowBalance: "DEPOSIT TO PLAY",
    minBetThreshold: "MIN BET $1",
    maxBetThreshold: "MAX BET $20 PER ROUND",
};

export const i18nPt = {
    winIfThree100s: "Ganhe se houver 3 lançamentos seguidos de 100",
    currency: "R$",
    quickBets: [5, 25, 50, 100],
    entries: "Entradas",
    // Controller
    bet: "Aposta",
    diceGuess: "Adivinhar",
    potentialWin: "Ganhar",
    winIfRollOverGuess: "Se o dado rodar mais que o seu palpite, você ganha!",
    inNextRoll: "VOCÊ ESTÁ NA PRÓXIMA RODADA",
    waitNextRoll: "AGUARDAR A PRÓXIMA ROLAGEM",
    placeBet: "FAZER APOSTA",
    nextRollIn: "Próxima rolagem",
    guess: "Palpite",
    youWon: "VOCÊ GANHOU",
    rolled: "Rolou",
    payout: "Pagamento",
    insufficientFundsWarning: "O jogador não tem fundos suficientes para esta aposta!",
    // Jackpot Modal 
    jackpotRulesTitle: "Pagamento",
    jackpotRules1: "Ganhe se houver 2 lançamentos seguidos de 100",
    jackpotRules2: "50% serão destinados a um portador de ingresso selecionado aleatoriamente.",
    jackpotRules3: "25% do pote é compartilhado igualmente entre os jogadores ativos durante a rodada vencedora.",
    jackpotRules4: "25% são divididos proporcionalmente entre os portadores de ingressos.",
    jackpotTerms: "Termos",
    jackpotKeepAnEye: "Fique de olho na contagem de bilhetes exibida junto com o valor do prêmio principal para estimar seu potencial de ganho.",
    returnHome: "FECHAR",
    // Nav
    balanceDeposit: "Depósito +",
    // Toasts
    lowBalance: "DEPOSIT TO PLAY",
    minBetThreshold: "MIN BET $1",
    maxBetThreshold: "MAX BET $20 PER ROUND",
}