export const BigFlame16 = (props) => {
  return (
    <svg width={props?.w ?? "50"} height={props?.h ?? "50"} viewBox={props?.v ?? "250 450 350 450"} strokeWidth={0}>
      <g id="Layer_2" data-name="Layer 2">
        <polygon points="287.81 675.26 314.41 643.54 489.07 592.4 548.62 768.9 376.64 833.43 339.14 830.67 287.81 675.26"/>
      </g>
      <g id="Layer_1" data-name="Layer 1">
        <g>
          <path fill="#e8c305" d="m247.97,322.11c-14.02-8.29-29.81-13.46-42-24.67-.64-.57-1.23-1.14-2.13-.41,7.53,43.68-19.59,60.32-31.49,96.8-13.06,39.19,14.21,59.3,38.14,84.9.37-.2.75-.41,1.12-.61-2.25-23.28-1.42-46.58,12.51-66.61,15.72-19.51,55.41-68.94,23.84-89.41Z"/>
          <path fill="#e8c305" d="m323.34,471.98c.49-.11.99-.23,1.48-.34-.78-15.13,5-33.12-14.59-37.13-14.01-4.31-27.8-9.02-40.97-15.47-.14-.07-.48.3-.96.62,16.89,44.45,36.79,26.16,55.04,52.34Z"/>
          <path fill="#e8c305" d="m552.05,768.25l-13.94-40.88c27.66-51.6.64-110.85-45.84-134.44l-.67-1.96c-.24-.71-1-1.11-1.72-.9l-2.26.65c-11.92-5.29-24.98-8.27-38.64-8.26-34.67-2.18-68.51,4.82-102.42,11.08,1,6.17,4.16,10.89,6.63,16.46-32.47,2.35-92.15,18.99-107.33-19.94-10.62,46.64-8.16,92.34-.82,138.16-3.05-.08-2.84-3.28-4.98-3.65-4.26,12.32.6,25.21,9.85,33.91,30.22,44.95,64,81.54,123.02,78.55,42.88-1.36,83.44-15.87,115.67-43.63l62.64-23.38c.71-.27,1.09-1.05.84-1.77Zm-181.15,60.65l-31.12-1.66c-.58-.03-1.08-.41-1.26-.96l-49.29-147.76c-.15-.45-.06-.95.23-1.32l21.31-26.95c.69-.88,2.08-.63,2.44.43l59.1,176.37c.32.94-.42,1.91-1.41,1.85Zm173.62-62.48l-163.18,60.26c-.74.28-1.57-.12-1.82-.87l-60.37-179.27c-.26-.76.17-1.58.95-1.8l166.64-47.98c.72-.21,1.48.19,1.72.9l56.9,166.98c.25.72-.13,1.51-.85,1.78Z"/>
          <ellipse fill="#e8c305" cx="435.89" cy="707.78" rx="18.5" ry="19.85" transform="translate(-208.53 182.73) rotate(-19.2)"/>
          <path fill="#e8c305" d="m301.66,692.86c-1.83.54-1.06,8.59,1.72,17.99,2.78,9.39,6.51,16.57,8.34,16.03,1.83-.54,1.06-8.59-1.72-17.99-2.78-9.39-6.51-16.57-8.34-16.03Z"/>
          <path fill="#e8c305" d="m342.8,767.47c-2,.7-.74,9.54,2.82,19.76,3.56,10.22,8.06,17.93,10.06,17.23,2-.7.74-9.54-2.82-19.76-3.56-10.22-8.06-17.93-10.06-17.23Z"/>
        </g>
      </g>
    </svg>
  )
}