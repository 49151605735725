
const Style: { [key: string]: React.CSSProperties } = {
    body: {
        width: "100%",
        // height: "100%",

        paddingLeft: "20px",
        paddingRight: "20px",
        paddingTop: "65px",

        display: "flex",
        flexDirection: "column",

        background: "#0B1437",

        overflowY: "auto"
    },
};

type Props = {
    children: JSX.Element | JSX.Element[]
}

export function Body({
    children
}: Props) {

    return (
        <section style={Style.body}>
            {children}
        </section>
    );
};











