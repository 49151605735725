
import { useState } from "react";
import "./ToggleSwitch.css"


type Props = {
    label: string;
    id: string;
}

export function ToggleSwitch({
    label = "",
    id = "",
}: Props) {

    return <div className="flexbox-horizontal flexbox-center">
        <div style={{ display: "flex", gap: "20px", alignItems: "center"}}>
            <label className="switch">
                <input type="checkbox" name={id} id={id}/>
                <span className="slider"></span>
            </label>
            <label style={{ bottom: "10px" }} htmlFor={id}>{ label }</label>
        </div>
    </div>;
};